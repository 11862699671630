import { Course } from '@/Types';

export const trimCourseData = (values: Partial<Course>) => {
  const trimmedValues = {
    ...values,
    capacity: values.capacity as number,
    mainCategory: values.mainCategory as number,
    internalName: values.internalName?.trim(),
    courseName: {
      de: values.courseName?.de.trim() || '',
      en: values.courseName?.en.trim() || '',
    },
    shortDescription: {
      de: values.shortDescription?.de.trim() || '',
      en: values.shortDescription?.en.trim() || '',
    },
  };

  delete trimmedValues.isNeededAccessoires;

  return trimmedValues;
};
