import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  createExternalConnector,
  getAllCheckinSystems,
  getAllExternalConnectors,
  updateExternalConnector,
} from '@/api/externalConnectors';

import useCurrentPartnerIdQuery from './useCurrentPartnerIdQuery';

const useAllExternalConnectors = () => {
  const partnerIdQuery = useCurrentPartnerIdQuery();

  const result = useQuery(['externalConnectors', partnerIdQuery.data], () =>
    getAllExternalConnectors(partnerIdQuery?.data || ''),
  );

  return result;
};

export const useCreateExternalConnectors = (id: number) => {
  const partnerIdQuery = useCurrentPartnerIdQuery();
  const queryClient = useQueryClient();

  return useMutation(() => createExternalConnector(partnerIdQuery?.data || '', id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['externalConnectors'] });
    },
  });
};
export const useUpdateExternalConnectors = (id: number) => {
  const partnerIdQuery = useCurrentPartnerIdQuery();
  const queryClient = useQueryClient();

  return useMutation(() => updateExternalConnector(partnerIdQuery?.data || '', id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['externalConnectors'] });
    },
  });
};

export const useAllCheckinSystems = () => useQuery(['checkinSystems'], getAllCheckinSystems);

export default useAllExternalConnectors;
