import { Theme } from '@mui/material';
import { CSSProperties } from 'react';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

const getHeaderStyle = (theme: Theme, variant: string) => {
  if (!theme.typography) return {} as CSSProperties;

  return variant === 'big'
    ? theme.typography.h2
    : { ...theme.typography.h2, fontSize: 16, lineHeight: 1.2 };
};

const useStyles = ({
  backgroundColor,
  fillColor,
  statusTagColor,
  variant = 'big',
}: {
  backgroundColor: string;
  fillColor: string;
  statusTagColor: string;
  variant?: 'big' | 'small';
}) =>
  makeStyles()(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: variant === 'big' ? '1rem 2rem' : '0.5rem 1rem',
      backgroundColor,
      ...getHeaderStyle(theme, variant),
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    infoIcon: {
      fill: fillColor,
      height: 32,
      width: 32,
      marginRight: '0.5rem',
    },
    closeContainer: {
      marginLeft: '1rem',
      display: 'flex',
      flexShrink: 0,
    },
    closeButton: {
      border: 'none',
      background: 'transparent',
      padding: '0.5rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: variant === 'big' ? '2rem' : '1rem',
    },
    image: variant === 'big' ? { width: 160, height: 'auto' } : { width: 100, height: 'auto' },
    imageContainer: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'center',
      width: variant === 'big' ? 160 : 100,
      height: variant === 'big' ? 160 : 100,
    },
    infoTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '2rem',
    },
    boldText: {
      fontWeight: 'bold',
    },
    statusTag: {
      marginTop: '1rem',
      backgroundColor: statusTagColor,
      color: FigmaDesignTokens.White,
      padding: '0.125rem 0.5rem',
      borderRadius: '0.2rem',
      width: 'fit-content',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    circleContainer: {
      position: 'absolute',
      bottom: variant === 'big' ? '2rem' : '1rem',
      right: variant === 'big' ? '2rem' : '1rem',
    },
    'sr-only': {
      position: 'absolute',
      width: 1,
      height: 1,
      padding: 0,
      margin: -1,
      overflow: 'hidden',
      clip: 'rect(0,0,0,0)',
      whiteSpace: 'nowrap',
      borderWidth: 0,
    },
    subTitle: {
      fontFamily: 'Roboto',
      fontSize: variant === 'big' ? 14 : 12,
      fontWeight: 'bold',
      textTransform: 'none',
      lineHeight: 1,
    },
    messageContainer: { display: 'flex', flexDirection: 'column' },
  }));

export default useStyles;
