import { InfoRounded } from '@mui/icons-material';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
  Stack,
} from '@mui/material';
import { Field, Form, getIn, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { FormikCheckbox } from '@/App/Shared/Form/Components/Formik/FormikCheckbox';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import theme from '@/App/Theme/Theme';
import { BodyTextRegular, DisplayTextAccent } from '@/Components/Typography';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { CountryDialCodes } from '@/Models';
import { useCurrentUserInfoQuery, useGetPartnerQuery } from '@/Queries';
import { HubspotSupportForm as HubspotSupportFormType } from '@/Types';
import { formatBytes } from '@/Utils';
import {
  ANCHOR_ID,
  CustomDependingField,
  ExtractedDependingValue,
  HubspotForm,
  MAX_FILE_SIZE,
  VALID_FILE_EXTENSIONS,
} from '@/Views/Support/Components/HubspotSupportForm';

import FigmaDesignTokens from '../../../design/design-tokens.json';
import { useAddressAndContactFormStyles } from '../AddressAndContactForm/AddressAndContactForm.styles';
import CondensedAccountInfo from './CondensedAccountInfo';
import FormikSingleUpload from './FormikSingleUpload';
import UploadedFileList from './UploadedFileList';

//special fields that we don't need show, but need to send
const CUSTOM_READONLY_FIELDS = ['email', 'lastname', 'firstname', 'company', 'pid', 'phone'];
const useCheckboxClasses = makeStyles()(() => ({
  checkboxWrapper: { display: 'flex', alignItems: 'center', cursor: 'pointer' },
  checkboxLabel: { display: 'block' },
}));

type HubspotFormProps = {
  supportFormStructure: HubspotSupportFormType | undefined;
  submissionSuccessfull: boolean | null;
  toggleLogoutModal: () => void;
  customFieldDependsOn: CustomDependingField[];
};

const HubspotEditForm: React.FC<HubspotFormProps> = ({
  supportFormStructure,
  submissionSuccessfull,
  toggleLogoutModal,
  customFieldDependsOn,
}) => {
  const intl = useMemoedIntl();
  const { data: partner } = useGetPartnerQuery();
  const { data: userAttributes } = useCurrentUserInfoQuery();

  const {
    classes: { checkboxWrapper, checkboxLabel },
  } = useCheckboxClasses();
  const { classes } = useAddressAndContactFormStyles();

  const { setFieldValue, values, errors, isSubmitting, submitCount, isValid } =
    useFormikContext<HubspotForm>();

  useEffect(() => {
    if (partner) {
      setFieldValue('pid', partner.casId);
      setFieldValue('company', partner.studioName);
    }
  }, [partner, setFieldValue]);

  useEffect(() => {
    if (userAttributes) {
      setFieldValue('firstname', userAttributes.attributes.given_name);
      setFieldValue('lastname', userAttributes.attributes.name);
      setFieldValue('email', userAttributes.attributes.email);
    }
  }, [setFieldValue, userAttributes]);

  useEffect(() => {
    if (supportFormStructure) {
      supportFormStructure.formFieldGroups.forEach(formField => {
        formField.fields.forEach(field => {
          if (field.fieldType === 'booleancheckbox') {
            setFieldValue(field.name, field.defaultValue && field.defaultValue === 'true');
            return;
          }
          if (field.fieldType === 'select' && field.selectedOptions.length !== 0) {
            setFieldValue(field.name, field.selectedOptions[0]);
          } else if (field.fieldType !== 'select' && field.defaultValue) {
            setFieldValue(field.name, field.defaultValue);
          }
        });
      });
    }
  }, [supportFormStructure, setFieldValue]);

  // watch to re-render list of form fields depending on value of art_des_anliegens___2024_ | see CUSTOM_FIELD_DEPENDS_ON
  const watchFieldsWithDependents = useMemo(
    () => customFieldDependsOn.map(field => getIn(values, field.dependsOn.parentField)),
    [values, customFieldDependsOn],
  );
  const watchFileUploads = getIn(values, 'hs_file_upload') as FileList;

  const checkCorrectValueForDependenField = useCallback(
    (dependsOnIndex: number, dependsOnValue: ExtractedDependingValue) => {
      if (watchFieldsWithDependents[dependsOnIndex].toString().length === 0) {
        return false;
      }
      let correctValue = false;
      if (Array.isArray(dependsOnValue)) {
        for (let i = 0; i < dependsOnValue.length; i++) {
          if (dependsOnValue[i] === watchFieldsWithDependents[dependsOnIndex]) {
            correctValue = true;
            break;
          }
        }
      } else {
        correctValue = watchFieldsWithDependents[dependsOnIndex] === dependsOnValue.toString();
      }
      return correctValue;
    },
    [watchFieldsWithDependents],
  );
  useEffect(() => {
    // reset fields that depends on another field
    customFieldDependsOn.forEach(({ field, dependsOn }, index) => {
      if (watchFieldsWithDependents[index] !== undefined) {
        if (!checkCorrectValueForDependenField(index, dependsOn.value)) setFieldValue(field, '');
      }
    });
  }, [
    watchFieldsWithDependents,
    setFieldValue,
    customFieldDependsOn,
    checkCorrectValueForDependenField,
  ]);

  const renderFieldGroups = useCallback(() => {
    if (!supportFormStructure) {
      return null;
    }

    return supportFormStructure.formFieldGroups.map((fieldGroup, index) =>
      fieldGroup.fields.filter(item => !item.hidden).length > 0 ? (
        <FormGroup
          key={index}
          sx={{
            alignItems: 'flex-start',
          }}>
          {fieldGroup.fields.map(field => {
            const { label, name, fieldType } = field;

            const customDependingFieldIndex = customFieldDependsOn.findIndex(
              ({ field: dependingFieldName }) => dependingFieldName === name,
            );

            //if field depends on another field, check if that field has correct value
            if (customDependingFieldIndex !== -1) {
              const customDependingField = customFieldDependsOn[customDependingFieldIndex];
              if (
                watchFieldsWithDependents[customDependingFieldIndex] === undefined ||
                !checkCorrectValueForDependenField(
                  customDependingFieldIndex,
                  customDependingField.dependsOn.value,
                )
              ) {
                return null;
              }
            }

            if (CUSTOM_READONLY_FIELDS.includes(name)) {
              return (
                <Field key={name} name={name}>
                  {() => <input type='hidden' />}
                </Field>
              );
            }

            if (fieldType === 'text' || fieldType === 'phonenumber' || fieldType === 'textarea') {
              return (
                <Field
                  key={name}
                  sx={{ marginBottom: '30px' }}
                  component={FormikTextField}
                  variant='outlined'
                  name={name}
                  type='text'
                  label={label.trim()}
                  required={field.required}
                  disabled={CUSTOM_READONLY_FIELDS.includes(name)}
                  multiline={fieldType === 'textarea'}
                  height={fieldType === 'textarea' ? 'auto' : undefined}
                  rows={fieldType === 'textarea' ? 5 : undefined}
                />
              );
            }

            if (fieldType === 'select') {
              return (
                <Field
                  key={name}
                  component={FormikTextField}
                  label={label.trim()}
                  variant='outlined'
                  sx={{ marginBottom: '30px' }}
                  name={name}
                  required={field.required}
                  select>
                  {field.options.map(option => (
                    <MenuItem value={option.value} key={option.label.trim()}>
                      {option.label.trim()}
                    </MenuItem>
                  ))}
                </Field>
              );
            }

            if (fieldType === 'checkbox') {
              return (
                <FormControl
                  key={label.trim()}
                  sx={{ marginBottom: '30px' }}
                  error={!!errors[name]}
                  required={field.required}>
                  <FormLabel id={name}>{label.trim()}</FormLabel>

                  <FormGroup>
                    {field.options.map(item => (
                      <label key={item.label.trim()} className={checkboxWrapper}>
                        <Field
                          name={name}
                          type='checkbox'
                          component={FormikCheckbox}
                          value={item.value}
                          required={field.required}
                        />
                        <span className={checkboxLabel}>{item.label.trim()}</span>
                      </label>
                    ))}
                  </FormGroup>

                  <FormHelperText>{errors[name]}</FormHelperText>
                </FormControl>
              );
            }
            if (fieldType === 'booleancheckbox') {
              return (
                <FormControl
                  sx={{ marginBottom: '30px' }}
                  error={!!errors[name]}
                  key={label.trim()}>
                  <label className={checkboxWrapper}>
                    <Field
                      key={label.trim()}
                      name={name}
                      type='checkbox'
                      component={FormikCheckbox}
                      required={field.required}
                    />
                    <span className={checkboxLabel}>{label.trim()}</span>
                  </label>

                  <FormHelperText>{errors[name]}</FormHelperText>
                  {field.unselectedLabel && <BodyTextRegular textContent={field.unselectedLabel} />}
                </FormControl>
              );
            }

            if (fieldType === 'file') {
              return (
                <Box
                  key={label.trim()}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}>
                  {watchFileUploads.length > 0 && (
                    <UploadedFileList
                      data={watchFileUploads}
                      onRemove={() => {
                        setFieldValue(name, []);
                      }}
                    />
                  )}

                  <Field name={name} key={label.trim()}>
                    {() => (
                      <FormikSingleUpload
                        name={name}
                        error={errors[name]}
                        required={field.required}
                        css={{
                          borderColor: theme.palette.primary.main,
                          color: theme.palette.primary.main,
                          gap: 4,
                        }}
                        variant='outlined'
                        validFileExtensions={VALID_FILE_EXTENSIONS}
                        disabled={watchFileUploads.length > 0}
                        setFieldValue={setFieldValue}
                        formHelperText={
                          <>
                            <Box
                              sx={{
                                marginTop: '10px',
                                marginBottom: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                fontSize: '16px',
                                color: FigmaDesignTokens.Grey[500],
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '4px',
                                  fontWeight: 600,
                                }}>
                                <InfoRounded fontSize='inherit' />

                                <span>
                                  {intl.formatMessage({
                                    id: 'support.contact.form.allowed_formats_label',
                                    defaultMessage: 'The following formats are permitted:',
                                  })}
                                </span>
                              </Box>

                              <Box>
                                {intl.formatMessage(
                                  {
                                    id: 'support.contact.form.allowed_formats_description',
                                    defaultMessage: '{validFileExtensions} up to {maxFileSize}',
                                  },
                                  {
                                    validFileExtensions: VALID_FILE_EXTENSIONS.join(', '),
                                    maxFileSize: formatBytes(MAX_FILE_SIZE),
                                  },
                                )}
                              </Box>
                            </Box>
                          </>
                        }>
                        <UploadIcon />
                        {`${intl.formatMessage({
                          id: 'form.generic.add_attachment',
                          defaultMessage: 'Add Attachment',
                        })} ${field.required ? '*' : ''}`}
                      </FormikSingleUpload>
                    )}
                  </Field>
                </Box>
              );
            }

            return null;
          })}
        </FormGroup>
      ) : null,
    );
  }, [
    errors,
    intl,
    setFieldValue,
    supportFormStructure,
    watchFieldsWithDependents,
    watchFileUploads,
    checkboxWrapper,
    checkboxLabel,
    checkCorrectValueForDependenField,
    customFieldDependsOn,
  ]);

  return (
    <Form>
      {isSubmitting && (
        <Backdrop open>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <DisplayTextAccent
        textContent={intl.formatMessage({
          id: 'support.contact.form.title',
          defaultMessage: 'Contact us',
        })}
        id={ANCHOR_ID}
      />
      <BodyTextRegular
        textContent={intl.formatMessage({
          id: 'support.contact.form.subtitle',
          defaultMessage: 'How may we help you?',
        })}
        customColor={FigmaDesignTokens.Grey[500]}
      />

      {partner && partner.casId && userAttributes && (
        <CondensedAccountInfo
          firstName={userAttributes.attributes.given_name}
          lastName={userAttributes.attributes.name}
          studioName={partner.studioName}
          email={userAttributes.attributes.email}
          companyId={partner.casId}
          actionButton={
            <Button variant='text' onClick={toggleLogoutModal} sx={{ padding: '0px' }}>
              {intl.formatMessage({
                id: 'support.contact.form.logout',
                defaultMessage: 'Log into another account',
              })}
            </Button>
          }
          containerCss={{ marginTop: '30px' }}
        />
      )}
      <Stack
        direction='row'
        spacing={2}
        sx={{
          marginBottom: '30px',
          marginTop: '30px',
        }}>
        <Field
          component={FormikTextField}
          label={intl.formatMessage({
            id: 'form.address_and_contact.countryPhoneNumberPrefix',
            defaultMessage: 'Prefix',
          })}
          variant='outlined'
          className={classes.countryPhoneNumberPrefix}
          name='dialCode'
          select>
          {CountryDialCodes.map(({ dialCode }) => (
            <MenuItem key={dialCode} value={dialCode}>
              {dialCode}
            </MenuItem>
          ))}
        </Field>
        <Field
          className={classes.phoneNumber}
          component={FormikTextField}
          variant='outlined'
          name='phoneNumber'
          type='text'
          label={intl.formatMessage({
            id: 'form.address_and_contact.phone',
            defaultMessage: 'Phone number (contact)',
          })}
        />
      </Stack>

      <Box
        sx={{
          marginBottom: '30px',
          marginTop: '30px',
        }}>
        <BodyTextRegular
          fontWeight={600}
          textContent={intl.formatMessage({
            id: 'support.contact.form.form_title',
            defaultMessage: 'Your request',
          })}
        />
      </Box>
      {renderFieldGroups()}

      <Button type='submit' disabled={!isValid} variant='contained' sx={{ marginBottom: '30px' }}>
        {intl.formatMessage({
          id: 'form.generic.submit',
          defaultMessage: 'Submit',
        })}
      </Button>

      {!submissionSuccessfull && submitCount > 0 && (
        <BodyTextRegular
          textContent={intl.formatMessage({
            id: 'support.contact.form.submit_error',
            defaultMessage: 'Error submitting form, please try again.',
          })}
        />
      )}
    </Form>
  );
};

export default HubspotEditForm;
