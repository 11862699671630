import { Box, Button } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';

type TextInputWithActionProps = {
  name: string;
  readOnly?: boolean;
  value?: string;
  onActionClick: () => void;
  startIcon?: React.ReactNode;
  actionLabel?: React.ReactNode;
  containerClassName?: string;
  style: React.CSSProperties;
};

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: `1px solid ${FigmaDesignTokens.Grey[400]}`,
    borderRadius: '4px',
    padding: '0 16px',
    justifyContent: 'space-between',
  },

  textField: {
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px' /* 150% */,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
  },
  cta: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    fontSize: '12px',
  },
  img: {
    marginRight: '4px',
    marginLeft: '2px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
}));

export const TextInputWithAction = ({
  name,
  readOnly,
  value,
  onActionClick,
  startIcon,
  actionLabel,
  containerClassName,
  style,
}: TextInputWithActionProps) => {
  const { classes } = useStyles();

  return (
    <Box className={clsx(classes.container, containerClassName)} style={style}>
      <input
        readOnly={readOnly}
        id={name}
        name={name}
        value={value}
        className={classes.textField}
      />

      <Button className={classes.cta} onClick={onActionClick}>
        {startIcon && <div className={classes.img}>{startIcon}</div>}
        {actionLabel}
      </Button>
    </Box>
  );
};
