import { Button, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { HTMLAttributes, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { CheckedInCheckedOutStatus } from '@/Components';
import { checkOutManually } from '@/Services';
import { CheckInCheckoutEventType } from '@/Types';
import { checkInColorHelper, combineStrings } from '@/Utils';
import handleCatchError from '@/Utils/handleCatchError';
import useCheckinStyles from '@/Views/CurrentCheckins/CurrentCheckins.style';

const CheckInRow = ({
  data,
  className,
}: {
  data: CheckInCheckoutEventType['data'];
  className: HTMLAttributes<HTMLDivElement>['className'];
}) => {
  const { classes: checkinClasses } = useCheckinStyles()();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const manualCheckoutAction = useCallback(async () => {
    try {
      await checkOutManually({
        euId: data.euId,
        partnerPublicId: data.partnerPublicId,
        referenceId: data.referenceId,
      });

      queryClient.invalidateQueries(['currentCheckins', data.partnerPublicId]);
    } catch (e) {
      handleCatchError(e);
    }
  }, [data.euId, data.partnerPublicId, data.referenceId, queryClient]);

  const backgroundColor = checkInColorHelper(data, theme);

  return (
    <div key={data.euId} className={className} style={{ backgroundColor }}>
      <div className={checkinClasses.avatarContainer}>
        <img src={data.euPictureUrl} className={checkinClasses.avatar} alt='' />
      </div>
      <div style={{ fontWeight: 'bold' }}>{combineStrings(data.firstname, data.lastname)}</div>
      <div>
        <CheckedInCheckedOutStatus
          checkInTimestamp={data.checkInTimestamp}
          checkOutTimestamp={data.checkOutTimestamp}
          checkInSource={data.checkInSource}
          checkOutSource={data.checkOutSource}
          offline={data.offline}
        />
      </div>
      {/* <div
              style={{
                fontSize: 11,
                textTransform: 'uppercase',
              }}>
              Details
            </div> */}
      <div
        style={{
          marginTop: -6,
          marginBottom: -6,
        }}>
        {!data.checkOutTimestamp ? (
          <Button
            variant='outlined'
            onClick={manualCheckoutAction}
            className={checkinClasses.checkoutButton}>
            <FormattedMessage
              id='view.current_checkins.table.cta.force_checkout'
              defaultMessage='Check-Out'
            />
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default CheckInRow;
