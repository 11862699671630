import { ManagedUpload } from 'aws-sdk/clients/s3';

import { getDefaultFilename } from '@/Mappers';
import { AssetService } from '@/Services';
import { Upload } from '@/Types';
import handleCatchError from '@/Utils/handleCatchError';

export const handleFileUpload = async (
  assetService: AssetService,
  casPublicId: string,
  file: File,
  uploadType: string,
  setUploads: (uploads: Upload[]) => void,
  successCallback: (filename: string, file: File) => void,
  errorMessage: string,
) => {
  try {
    const filename = getDefaultFilename(file.name);
    const managedUpload = await assetService.S3Upload(
      casPublicId,
      filename,
      uploadType,
      file,
      (progressEvent: ManagedUpload.Progress) => {
        const upload: Upload = {
          file,
          progress: progressEvent,
          managedUpload,
        };

        setUploads([upload]);
      },
      (err: Error | null) => {
        if (err) {
          setUploads([
            {
              file,
              errorMessage,
            },
          ]);
          return;
        }

        successCallback(filename, file);
      },
    );
  } catch (error) {
    handleCatchError(error);
  }
};
