import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@/Context';
import { PartnerCoursesServiceFactory } from '@/Services';
import { Partner } from '@/Types';

type useGetPartnerCoursesQueryProps = {
  casPublicId: Partner['casPublicId'];
  isActiveCourse: boolean;
  page: number;
  limit: number;
  filter?: GridFilterModel;
};

const useGetPartnerCoursesQuery = ({
  casPublicId,
  isActiveCourse,
  page,
  limit,
  filter,
}: useGetPartnerCoursesQueryProps) => {
  const { dispatch } = useAppContext();
  const partnerCoursesService = new PartnerCoursesServiceFactory().getInstance(dispatch);

  const queryResult = useQuery(
    ['useGetPartnerCoursesQuery', isActiveCourse, casPublicId, page, limit, filter],
    () => partnerCoursesService.getCourses(casPublicId, isActiveCourse, page, limit, filter),
    {
      cacheTime: 0,
      enabled: !!casPublicId,
    },
  );

  return queryResult;
};

export default useGetPartnerCoursesQuery;
