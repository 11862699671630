import { Box, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { de, enGB } from 'date-fns/locale';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { useHeaderTableStyles } from '@/App/Shared/DataGridTable/Table/Table.styles';
import { BodyTextSmall, Header, SubHeadlineDisplay } from '@/Components';
import useStaticStatisticBreadcrumbs from '@/Hooks/Breadcrumbs/useStaticStatisticBreadcrumbs';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { getStaticPathname } from '@/Mappers';
import { useCheckInStatsQuery, useGetPartnerQuery } from '@/Queries';
import { DateSelectionType, GetTrainInstrStatsResponseType, StatisticsType } from '@/Types';
import { defaultStatisticsRange, formatDateQl } from '@/Utils';
import CheckInStatsTable from '@/Views/CheckInAll/Components/CheckInStatsTable';

const CheckInTrainInstruction = () => {
  const { classes } = useHeaderTableStyles();
  const currentLanguage = useCurrentLanguage();
  const { formatMessage } = useIntl();
  const { data: partnerData } = useGetPartnerQuery();

  const [dates, setDates] = useState<DateSelectionType>(defaultStatisticsRange);

  const { data, isLoading } = useCheckInStatsQuery<GetTrainInstrStatsResponseType>({
    partnerPublicId: partnerData?.publicId,
    statsType: StatisticsType.Training,
    endDate: dates.endDate && formatDateQl(dates.endDate),
    startDate: dates.startDate && formatDateQl(dates.startDate),
  });

  const staticStatisticBreadcrumbs = useStaticStatisticBreadcrumbs();
  const currentBreadcrumbs = useMemo(
    () => [
      ...staticStatisticBreadcrumbs,
      {
        title: formatMessage({
          id: 'statistic.title.check_in_training_instruction',
          defaultMessage: 'Training instruction',
        }),
        href: getStaticPathname(currentLanguage, 'training-instruction'),
      },
    ],
    [currentLanguage, staticStatisticBreadcrumbs, formatMessage],
  );

  const totalNumberOfCheckins = useMemo(() => {
    if (!data?.data) return 0;

    return Object.keys(data?.data || {}).length;
  }, [data?.data]);

  return (
    <>
      <Header
        showLanguageSwitcher={false}
        header={formatMessage({
          id: 'statistic.title.training_instruction',
          defaultMessage: 'Training Instruction',
        })}
        breadcrumbs={currentBreadcrumbs}
      />

      <Box mt={4}>
        <div
          style={{
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Box
            className={classes.checkIn}
            mb={2}
            style={{
              width: 'fit-content',
            }}>
            <BodyTextSmall
              fontWeight={500}
              textContent={`${formatMessage({
                id: 'view.statistic.company.number_check_in',
                defaultMessage: 'Number of check-ins',
              })}: `}
            />
            <SubHeadlineDisplay textContent={totalNumberOfCheckins.toString()} />
          </Box>
        </div>
        <ContentContainer padding={0}>
          <Grid item xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={currentLanguage === 'en' ? enGB : de}>
              <CheckInStatsTable<GetTrainInstrStatsResponseType>
                data={data?.data || []}
                dateRange={dates}
                setDateRange={setDates}
                isLoading={isLoading}
                lastColumn='coupon_redemption_date'
              />
            </LocalizationProvider>
          </Grid>
        </ContentContainer>
      </Box>
    </>
  );
};

export default CheckInTrainInstruction;
