import { Icon } from '@mui/material';

import { ReactComponent as Arrow } from '@/Static/Icons/atoms-symbols-arrow-light.svg';
import DatePickerIcon from '@/Static/Icons/atoms-symbols-icons-calendar.svg';

export const SelectIcon = () => (
  <Arrow className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined' />
);

export const PickerIcon = () => (
  <Icon>
    <img src={DatePickerIcon} alt='' />
  </Icon>
);
