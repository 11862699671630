import { withStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';

export const RadioStyles = (component: any) =>
  withStyles(component, theme => ({
    root: {
      '& .MuiFormControlLabel-root': {
        justifyContent: 'center',
      },
      '& .MuiSvgIcon-root:nth-of-type(1)': {
        fill: FigmaDesignTokens.Grey[950],
      },

      '& .MuiFormControlLabel-root.Mui-disabled .MuiSvgIcon-root:nth--of-type(1)': {
        fill: FigmaDesignTokens.Grey[300],
      },
      '& .MuiFormControlLabel-root.Mui-disabled .MuiSvgIcon-root:nth-of-type(2)': {
        fill: FigmaDesignTokens.Grey[600],
      },
    },
  }));
