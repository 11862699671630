import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { AxiosError, AxiosResponse } from 'axios';

import { HttpService } from '@/Services';
import { Category, Course, CoursesData } from '@/Types';

export enum COURSE_ACTIONS {
  SAVE = 'SAVE',
  ARCHIVE = 'ARCHIVE',
  DELETE = 'DELETE',
}

const API_URL = process.env.REACT_APP_PIMCORE_ENDPOINT;

const translateOperator = (operator: string) => {
  switch (operator) {
    case 'equals':
      return 'eq';
    case 'doesNotEqual':
      return 'neq';
    case 'is':
      return 'eq';
    case 'not':
      return 'neq';
    case '=':
      return 'eq';
    case '!=':
      return 'neq';
    case '>=':
      return 'gte';
    case '<=':
      return 'lte';
    case '>':
      return 'gt';
    case '<':
      return 'lt';
    case 'onOrAfter':
      return 'gte';
    case 'onOrBefore':
      return 'lte';
    case 'before':
      return 'lt';
    case 'after':
      return 'gt';
    case 'isNotEmpty':
      return 'neq';
    case 'isEmpty':
      return 'eq';

    default:
      return operator;
  }
};

export const convertGridFilterModelToURLParams = (filterModel: GridFilterModel): string => {
  if (!filterModel || !filterModel.items || filterModel.items.length === 0) {
    return '';
  }

  const params = filterModel.items
    .filter(
      item =>
        item.field &&
        item.operator &&
        (item.value || item.operator === 'isEmpty' || item.operator === 'isNotEmpty'),
    ) // Ensure valid filter items but allow empty values on operators 'isEmpty' and 'isNotEmpty'
    .map(item => {
      if (
        (item.field === 'startDate' ||
          item.field === 'endDate' ||
          item.field === 'nextEventDate') &&
        item.value instanceof Date
      ) {
        item.value = item.value.toISOString().substring(0, 10);
      }

      return `filter[${encodeURIComponent(item.field)}][${encodeURIComponent(
        translateOperator(item.operator),
      )}]=${encodeURIComponent(item.value)}`;
    })
    .join('&');

  return params;
};

export class PartnerCoursesService {
  public httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  public async getCourseCategories(
    language: string,
  ): Promise<AxiosResponse<Category[]> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.get(`${API_URL}api/course_categories?language=${language}`);
  }

  public async getCourse(
    id: string,
    courseId: number | string,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.get(`${API_URL}api/partners/${id}/courses/${courseId}`);
  }

  public async getCourses(
    id: string,
    isActiveCourse: boolean,
    currentPage: number,
    limit: number,
    filter?: GridFilterModel,
  ): Promise<CoursesData> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();
    let url = `${API_URL}api/partners/${id}/courses?open_events=${isActiveCourse}&pagination[limit]=${limit}&pagination[page]=${currentPage}`;

    // Add filters if provided
    if (filter) {
      const params = convertGridFilterModelToURLParams(filter);

      if (params.length > 0) {
        url += `&${params}`;
      }
    }

    try {
      const coursesData = (await axiosInstance.get(url)).data as CoursesData;

      return coursesData;
    } catch (e) {
      throw new Error(`Failed to fetch courses: ${(e as AxiosError).message}`);
    }
  }

  public async createOnsiteCourse(
    id: string,
    data: Partial<Course>,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    const adjustedData = {
      ...data,
      courseDescription: data.shortDescription,
      courseLevel: data.level,
      courseCapacity: data.capacity,
      courseNeededAccessories: data.neededAccessoires,
      courseMainCategory: data.mainCategory,
    };

    return await axiosInstance.post(`${API_URL}api/partners/${id}/onsite/courses`, adjustedData);
  }

  public async createOnlineCourse(
    id: string,
    data: Partial<Course>,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.post(`${API_URL}api/partners/${id}/courses`, data);
  }

  public async updateOnsiteCourse(
    id: string,
    courseId: number,
    data: Partial<Course>,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxiosForPatchRequest();

    const adjustedData = {
      ...data,
      courseDescription: data.shortDescription,
      courseLevel: data.level,
      courseCapacity: data.capacity,
      courseNeededAccessories: data.neededAccessoires,
      courseMainCategory: data.mainCategory,
    };

    return await axiosInstance.patch(
      `${API_URL}api/partners/${id}/courses/${courseId}/onsite`,
      adjustedData,
    );
  }

  public async updateOnlineCourse(
    id: string,
    courseId: number,
    data: Partial<Course>,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxiosForPatchRequest();

    return await axiosInstance.patch(`${API_URL}api/partners/${id}/courses/${courseId}`, data);
  }

  public async deleteCourse(
    id: string,
    courseId: number,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxiosForPatchRequest();

    return await axiosInstance.delete(`${API_URL}api/partners/${id}/courses/${courseId}`);
  }

  public async archiveCourse(
    id: string,
    courseId: number,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.put(`${API_URL}api/partners/${id}/courses/${courseId}`, {
      archived: true,
    });
  }

  public async activateServicePackage(
    id: string,
    servicePackageName: string,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.put(`${API_URL}api/partners/${id}/service_packages`, {
      servicePackageName,
    });
  }
}
