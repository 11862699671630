import { CheckInSystem, ExternalConnector } from '@/Types/externalConnectors';
import { Auth } from 'aws-amplify';
import axios from 'axios';

export const getAllCheckinSystems = async () => {
  const currentSession = await Auth.currentSession();
  const customHeaders = {
    Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
  };

  const instance = axios.create({
    timeout: 30000,
    headers: customHeaders,
  });

  const query = await instance.get<CheckInSystem[]>(
    `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/check-in-system`,
  );

  return query.data;
};

export const getAllExternalConnectors = async (partnerId: string) => {
  const currentSession = await Auth.currentSession();
  const customHeaders = {
    Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
  };

  const instance = axios.create({
    timeout: 30000,
    headers: customHeaders,
  });

  const query = await instance.get<ExternalConnector[]>(
    `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${partnerId}/externalConnector`,
  );

  return query.data;
};
export const getExternalConnector = async (partnerId: string, id: number) => {
  const currentSession = await Auth.currentSession();
  const customHeaders = {
    Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
  };

  const instance = axios.create({
    timeout: 30000,
    headers: customHeaders,
  });

  const query = await instance.get<ExternalConnector>(
    `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${partnerId}/externalConnector/${id}`,
  );

  return query.data;
};
export const createExternalConnector = async (partnerId: string, checkInSystemId: number) => {
  const currentSession = await Auth.currentSession();
  const customHeaders = {
    Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
  };

  const instance = axios.create({
    timeout: 30000,
    headers: customHeaders,
  });

  const query = await instance.post<ExternalConnector>(
    `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${partnerId}/externalConnector`,
    {
      checkInSystemId,
    },
  );

  return query.data;
};
export const updateExternalConnector = async (partnerId: string, id: number) => {
  const currentSession = await Auth.currentSession();
  const customHeaders = {
    Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
    'Content-Type': 'application/merge-patch+json',
  };

  const instance = axios.create({
    timeout: 30000,
    headers: customHeaders,
  });

  const query = await instance.patch<ExternalConnector>(
    `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${partnerId}/externalConnector/${id}`,
    {},
  );

  return query.data;
};
