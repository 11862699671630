import { Grid, MenuItem } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import KeyboardDatePicker from '@/App/Shared/Form/Components/Formik/FormikDatePicker';
import { FormikErrorMessage } from '@/App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { SubHeader } from '@/App/Shared/Form/Components/Header/SubHeader';
import {
  Categories,
  PickerIcon as DatePickerIcon,
  SelectedCategories,
  SelectIcon,
  TimeZoneModal,
} from '@/Components';
import { WeekdayService } from '@/Mappers/Weekday';
import { useGetPartnerQuery } from '@/Queries';
import { EventsService } from '@/Services';
import { CategoriesTypes, DayOption, Event, SelectOption } from '@/Types';

import { AppointmentsTime } from './AppointmentsTime';

type SeriesEventsFormProps = {
  days: DayOption[];
  handleDayDelete: (day: DayOption | CategoriesTypes) => void;
  handleDaySelect: (id: string) => void;
  intervals: SelectOption[];
  eventsService: EventsService;
};

const useStyles = makeStyles()(() => ({
  datepicker: {
    '& .MuiIconButton-root': {
      position: 'relative',
    },
  },
}));

export const SeriesEventsForm = ({
  days,
  handleDaySelect,
  handleDayDelete,
  intervals,
  eventsService,
}: SeriesEventsFormProps) => {
  const { values, setFieldValue } = useFormikContext<Event>();
  const intl = useIntl();
  const { classes } = useStyles();
  const [weekdayService] = useState<WeekdayService>(new WeekdayService());
  const [disabledWeekdays, setDisabledWeekdays] = useState<number[]>([]);
  const { data: partner } = useGetPartnerQuery();
  const minDate = useMemo(
    () =>
      moment(values.appointments.startDate || new Date())
        .add(1, 'day')
        .toDate(),
    [values.appointments.startDate],
  );
  const maxDate = useMemo(
    () =>
      moment(values.appointments.startDate || new Date())
        .add(1, 'years')
        .toDate(),
    [values.appointments.startDate],
  );

  useEffect(() => {
    if (values.appointments.startDate && values.appointments.seriesSettings?.endDate) {
      const startDate = moment(values.appointments.startDate).toDate();
      const endDate = moment(values.appointments.seriesSettings.endDate).toDate();

      setDisabledWeekdays(weekdayService.getDisabledWeekdaysByRange(startDate, endDate));

      if (weekdayService.hasLessThanSevenDays(startDate, endDate)) {
        setFieldValue('appointments.seriesSettings.weekdays', []);
      }
    } else {
      setFieldValue('appointments.seriesSettings.weekdays', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.appointments.startDate, values.appointments.seriesSettings?.endDate, weekdayService]);

  return (
    <>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          <Field
            className={classes.datepicker}
            component={KeyboardDatePicker}
            name='appointments.startDate'
            value={values.appointments.startDate}
            label={intl.formatMessage({
              id: 'form.event.field.appointments.start_date.label',
              defaultMessage: 'Course date*',
            })}
            onChange={(date: Date) => {
              if (date) {
                setFieldValue('appointments.startDate', date);
              }
            }}
            variant='inline'
            inputVariant='outlined'
            views={['year', 'month', 'day']}
            format='dd.MM.yyyy'
            components={{
              OpenPickerIcon: DatePickerIcon,
            }}
            disablePast
            maxDate={maxDate}
            minDate={new Date()}
            autoComplete='off'
            autoOk
          />
          <FormikErrorMessage name='appointments.startDate' />
        </Grid>

        <Grid item xs={6}>
          <Field
            className={classes.datepicker}
            component={KeyboardDatePicker}
            name='appointments.seriesSettings.endDate'
            value={values.appointments.seriesSettings?.endDate}
            label={intl.formatMessage({
              id: 'form.event.field.appointments.end_date.label',
              defaultMessage: 'End date*',
            })}
            variant='inline'
            inputVariant='outlined'
            onChange={(date: Date) => {
              setFieldValue('appointments.seriesSettings.endDate', date);
            }}
            format='dd.MM.yyyy'
            components={{
              OpenPickerIcon: DatePickerIcon,
            }}
            autoComplete='off'
            autoOk
            disabled={!minDate}
            minDate={minDate}
            maxDate={maxDate}
          />
          <FormikErrorMessage name='appointments.seriesSettings.endDate' />
        </Grid>
      </Grid>

      <Grid container item spacing={3}>
        <Grid item xs={6}>
          <Field
            component={FormikTextField}
            variant='outlined'
            name='appointments.seriesSettings.repetitions'
            label={intl.formatMessage({
              id: 'form.event.field.appointments.seriesSettings.repetitions.label',
              defaultMessage: 'Repetition/interval',
            })}
            select
            SelectProps={{
              IconComponent: SelectIcon,
            }}>
            {intervals.map((type: SelectOption) => (
              <MenuItem key={type.value} value={type.value}>
                {type.displayValue}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>

      <Grid xs={8} item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubHeader
              title={intl.formatMessage({
                id: 'form.event.weekdays.subheader',
                defaultMessage: 'Weekday(s):',
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectedCategories
              disabled={false}
              selectedCategories={values.appointments.seriesSettings?.weekdays as number[]}
              categories={days}
              handleDelete={handleDayDelete}
              name='appointments.seriesSettings.weekdays'
            />
            <FormikErrorMessage name='appointments.seriesSettings.weekdays' />
          </Grid>
          <Grid item xs={12}>
            <Categories
              multiSelect
              selectedCategories={values.appointments.seriesSettings?.weekdays || []}
              handleClick={handleDaySelect}
              categories={days}
              disabledIds={disabledWeekdays}
              name='appointments.seriesSettings.weekdays'
              readOnly={partner?.readonly}
            />
          </Grid>
        </Grid>
      </Grid>

      <AppointmentsTime
        eventsService={eventsService}
        hourName='appointments.hour'
        minuteName='appointments.minute'
      />

      <Grid item xs={6}>
        <TimeZoneModal />
      </Grid>
    </>
  );
};
