import { Box, Divider, Grid, Link } from '@mui/material';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormikSwitch } from '@/App/Shared/Form/Components/Formik/FormikSwitch';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { SubHeader } from '@/App/Shared/Form/Components/Header/SubHeader';
import { SwitchLabel } from '@/App/Shared/Form/Components/Label/SwitchLabel';
import { InfoMessage, OverlineText } from '@/Components';
import { DelayModalTypes, useAppContext } from '@/Context';
import { queryClient } from '@/Helpers';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import useIsFirstTranslation from '@/Hooks/useIsFirstTranslation';
import useReadStorage from '@/Hooks/useReadStorage';
import { useTranslationMutation, useUpdatePartnerDataMutation } from '@/Queries';
import { CheckInOptionsPartial, GeneralInformationFormsProps, LanguageType } from '@/Types';
import { CheckInOptionsValidation, getTargetLanguage, removeSymbols } from '@/Utils';
import handleCatchError from '@/Utils/handleCatchError';

import { useCheckInOptionsFormStyles } from './CheckInOptionsForm.styles';

export const CheckInOptionsForm = ({
  refCallback,
  casPublicId,
  handlePrimaryCtaDisabled,
  setOpenToastMessage,
  partner,
}: GeneralInformationFormsProps) => {
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();

  const { classes } = useCheckInOptionsFormStyles();
  const formikRef = useRef<FormikProps<CheckInOptionsPartial>>(null);
  const pageLanguage = useCurrentLanguage();

  const currentLanguage =
    useReadStorage<LanguageType>('vpp_translation_content_language', 'LOCAL_STORAGE') ||
    pageLanguage;
  const targetLanguage = getTargetLanguage(currentLanguage);
  const { isFirstTranslation, setIsFirstTranslation } = useIsFirstTranslation();
  const intl = useIntl();

  const handleConfirmDialog = useConfirmDialog();

  const initialState = useMemo<CheckInOptionsPartial>(
    () => ({
      twogether: partner.twogether,
      checkinInformation: partner.checkinInformation,
    }),
    [partner],
  );

  const { mutateAsync: updatePartnerData } = useUpdatePartnerDataMutation();

  const { mutateAsync: translationMutate } = useTranslationMutation();

  const handleTranslation = useCallback(
    async (textToTranslate: string, field: string) => {
      if (formikRef.current?.setFieldValue && currentLanguage) {
        await translationMutate({
          currentValue: textToTranslate,
          field,

          currentLanguage,

          setFieldValue: formikRef.current?.setFieldValue,
          isFirstTranslation: isFirstTranslation as boolean,
          setIsFirstTranslation,
          targetLanguage,
        });
        handleConfirmDialog(true);
        handlePrimaryCtaDisabled(false);
      }
    },
    [
      currentLanguage,
      translationMutate,
      isFirstTranslation,
      setIsFirstTranslation,
      targetLanguage,
      handleConfirmDialog,
      handlePrimaryCtaDisabled,
    ],
  );

  useEffect(() => {
    refCallback(formikRef, 1);
  }, [refCallback, formikRef]);

  const handleSubmit = async (formData: CheckInOptionsPartial) => {
    if (!delayModal.notShowProfileModal) {
      dispatch({ type: DelayModalTypes.SET_OPEN, payload: { open: true } });
    }

    try {
      await updatePartnerData({ casPublicId, updatedData: formData });
      queryClient.invalidateQueries(['useGetPartnerQuery']);

      handlePrimaryCtaDisabled(true);
      handleConfirmDialog(false);
      setOpenToastMessage(true);
    } catch (error) {
      handleCatchError(error);
    }
  };

  // Trigger validation on language change
  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [intl.locale]); // Run when the language changes

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialState}
      validationSchema={() => CheckInOptionsValidation(intl)}
      validate={values => {
        const initialStateStringify = JSON.stringify(initialState);
        const currentStateStringify = JSON.stringify(values);

        if (initialStateStringify !== currentStateStringify) {
          handleConfirmDialog(true);
          handlePrimaryCtaDisabled(false);
        }
      }}
      onSubmit={handleSubmit}>
      {({ values: { twogether } }) => (
        <ContentContainer>
          <ConnectedFocusError />
          <Form>
            <Grid container spacing={4}>
              <Grid item container spacing={4}>
                <Grid item xs={12}>
                  <FormHeader
                    title={intl.formatMessage({
                      id: 'form.check_in_options.header.check_in_options',
                      defaultMessage: 'Check-In Options ',
                    })}
                  />
                </Grid>

                <Grid xs={8} item>
                  <Grid container spacing={0}>
                    <InfoMessage
                      title={intl.formatMessage({
                        id: 'form.check_in_options.checkin_information.warning_message_title',
                        defaultMessage: 'Update on check-in options',
                      })}
                      description={intl.formatMessage({
                        id: 'form.check_in_options.checkin_information.warning_message_description',
                        defaultMessage:
                          'In order to further digitize our processes and meet the wishes of many customers, we have decided to abolish the Hansefit card. From January 1st, 2024, check-in will only be possible via the Hansefit app. So that all customers and partners can prepare well for the change, we have granted a transition period of 1.5 years (since July 1, 2022).',
                      })}
                      type='warningAlert'
                      Action={
                        <Link
                          href={intl.formatMessage({
                            id: 'form.check_in_options.checkin_information.action',
                            defaultMessage: 'https://hansefit.de/partner/faq/app',
                          })}
                          target='_blank'>
                          {intl.formatMessage({
                            id: 'form.check_in_options.checkin_information.action_text',
                            defaultMessage: 'Go to FAQ',
                          })}
                        </Link>
                      }
                    />
                    <Grid item xs={12} sx={{ mt: 3 }}>
                      <Box className={classes.checkinInformationWrapper}>
                        <Field
                          onBlur={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                            handleTranslation(removeSymbols(e), 'checkinInformation')
                          }
                          placeholder={intl.formatMessage({
                            id: 'form.check_in_options.checkin_information.placeholder',
                            defaultMessage:
                              'Free text field for further information (e.g. access via turnstile with Hansefit ID)',
                          })}
                          component={FormikTextField}
                          multiline
                          rows={4}
                          variant='outlined'
                          name={`checkinInformation.${currentLanguage}`}
                          type='text'
                          label={`${intl.formatMessage({
                            id: 'form.check_in_options.checkin_information.label',
                            defaultMessage: 'Further information on check-in',
                          })} | ${currentLanguage?.toUpperCase()}`}
                          disabled={partner.readonly}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider className={classes.divider} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={8}>
                    <SubHeader
                      title={intl.formatMessage({
                        id: 'form.check_in_options.twogether.header',
                        defaultMessage: 'Participation in the Twogether program*',
                      })}
                    />
                    <Box className={classes.switchWrapper}>
                      <Field
                        color='primary'
                        type='checkbox'
                        component={FormikSwitch}
                        name='twogether'
                        disabled={partner.readonly}
                      />
                      <SwitchLabel
                        title={
                          twogether
                            ? intl.formatMessage({
                                id: 'form.check_in_options.twogether.yes',
                                defaultMessage: 'Yes',
                              })
                            : intl.formatMessage({
                                id: 'form.check_in_options.twogether.no',
                                defaultMessage: 'No',
                              })
                        }
                      />
                    </Box>
                    <OverlineText
                      textContent={intl.formatMessage({
                        id: 'common.required',
                        defaultMessage: '*Compulsory fields ',
                      })}
                      fontStyle='italic'
                      textTransform='capitalize'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoMessage
                      title={intl.formatMessage({
                        id: 'form.check_in_options.hint.twogether.header',
                        defaultMessage: 'What is the Twogether program?',
                      })}
                      description={intl.formatMessage({
                        id: 'form.check_in_options.hint.twogether.description',
                        defaultMessage:
                          'With the Twogether program, friends and family of Hansefit members receive a 20% discount on monthly dues and one-time costs.',
                      })}
                      type='information'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </ContentContainer>
      )}
    </Formik>
  );
};
