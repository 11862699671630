import { CheckCircleRounded, ContentCopyOutlined } from '@mui/icons-material';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { TextInputWithAction } from '@/App/Shared/Form/Components/Customized/TextInputWithAction';
import { Notification } from '@/App/Shared/Notification/Components/Notification';
import { Button, Header, SubHeadlineDisplay } from '@/Components';
import useSettingsBreadcrumbs from '@/Hooks/Breadcrumbs/useSettingsBreadcrumbs';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import useAllExternalConnectors, {
  useAllCheckinSystems,
  useCreateExternalConnectors,
  useUpdateExternalConnectors,
} from '@/Queries/useExternalConnectors';

import FigmaDesignTokens from '../../design/design-tokens.json';

type Option = {
  label: string;
  id: number;
};

const Settings = () => {
  const { formatMessage } = useMemoedIntl();

  const currentBreadcrumbs = useSettingsBreadcrumbs();
  const [hasCopied, setHasCopied] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [generatedAt, setGeneratedAt] = useState('');
  const [selectedThirdPartyApi, setSelectedThirdPartyApi] = useState<Option | null>();
  const { data: listExternalConnectors } = useAllExternalConnectors();
  const { data: listCheckInSystems } = useAllCheckinSystems();
  const { mutateAsync: createExternalConnectors } = useCreateExternalConnectors(
    selectedThirdPartyApi?.id || 0,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  // we only have one external connector
  const firstExternalConnector = listExternalConnectors?.[0];

  const { mutateAsync: updateExternalConnectors } = useUpdateExternalConnectors(
    firstExternalConnector?.id || 0,
  );

  const handleGetApiKey = useCallback(async () => {
    setIsLoading(true);

    let result;

    try {
      if (firstExternalConnector) {
        result = await updateExternalConnectors();
      } else {
        result = await createExternalConnectors();
      }

      setApiKey(result.apiKey);
      setGeneratedAt(new Date(result.apiKeyUpdatedAt * 1000).toLocaleString());

      setIsLoading(false);
      setErrorMessage(undefined);
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMessage(
          formatMessage({
            id: 'view.settings.api_key_error',
            defaultMessage: 'Something went wrong. Please try again later.',
          }),
        );
      }
      setIsLoading(false);
    }
  }, [createExternalConnectors, firstExternalConnector, formatMessage, updateExternalConnectors]);

  const handleCopyClick = useCallback(() => {
    navigator.clipboard.writeText(apiKey);
    setHasCopied(true);
    setTimeout(() => {
      setHasCopied(false);
    }, 3000);
  }, [apiKey]);

  const externalConnectorOptions: Option[] = useMemo(() => {
    if (!listCheckInSystems) {
      return [];
    }

    return listCheckInSystems
      .filter(f => f.isDisplayed)
      .map(connector => ({
        label: connector.name,
        id: connector.id,
      }));
  }, [listCheckInSystems]);

  useEffect(() => {
    if (firstExternalConnector?.checkInSystemId) {
      setApiKey(firstExternalConnector.apiKey);
      setGeneratedAt(new Date(firstExternalConnector.apiKeyUpdatedAt * 1000).toLocaleString());
      setSelectedThirdPartyApi(
        externalConnectorOptions.find(f => f.id === firstExternalConnector.checkInSystemId),
      );
    }
  }, [
    listExternalConnectors,
    listCheckInSystems,
    externalConnectorOptions,
    firstExternalConnector,
  ]);

  return (
    <>
      <Header
        showLanguageSwitcher={false}
        header={formatMessage({
          id: 'view.settings',
          defaultMessage: 'Settings',
        })}
        breadcrumbs={currentBreadcrumbs}
      />
      <Box height={16} />
      <ContentContainer>
        <SubHeadlineDisplay
          textContent={formatMessage({
            id: 'view.settings.connection_title',
            defaultMessage: 'Connection to third party applications',
          })}
        />
        <Grid container item spacing={4} xs={8}>
          <Grid item>
            <Typography variant='body2'>
              <FormattedMessage
                id='view.settings.connection_description'
                defaultMessage='Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.'
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id='thidPartyApi'
              options={externalConnectorOptions}
              value={selectedThirdPartyApi}
              disabled={!!selectedThirdPartyApi}
              sx={{ width: 300 }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={
                    !selectedThirdPartyApi ? (
                      <FormattedMessage
                        id='form.select_third_party'
                        defaultMessage='Select 3rd party system'
                      />
                    ) : (
                      selectedThirdPartyApi.label
                    )
                  }
                />
              )}
              onChange={(_, value) => setSelectedThirdPartyApi(value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              onClick={handleGetApiKey}
              variant={selectedThirdPartyApi ? 'contained' : 'outlined'}
              fontSize='12'
              isLoading={isLoading}
              disabled={!selectedThirdPartyApi}>
              {apiKey ? (
                <FormattedMessage
                  id='view.settings.button_recreate_api_key'
                  defaultMessage='Create new API key'
                />
              ) : (
                <FormattedMessage
                  id='view.settings.button_create_api_key'
                  defaultMessage='Create API key'
                />
              )}
            </Button>
          </Grid>
          {errorMessage ? (
            <Grid item xs={12}>
              <Notification severity='error' message={errorMessage} />
            </Grid>
          ) : null}
          {apiKey && (
            <Grid item>
              <TextInputWithAction
                name='apiKey'
                readOnly
                value={apiKey}
                onActionClick={handleCopyClick}
                startIcon={hasCopied ? <CheckCircleRounded /> : <ContentCopyOutlined />}
                actionLabel={
                  <>
                    {hasCopied ? (
                      <FormattedMessage
                        id='view.settings.copy_button_success'
                        defaultMessage='Copied'
                      />
                    ) : (
                      <FormattedMessage id='view.settings.copy_button' defaultMessage='Copy' />
                    )}
                  </>
                }
                style={{ width: 400 }}
              />
              <Typography variant='body2' sx={{ marginTop: 1, color: FigmaDesignTokens.Grey[500] }}>
                <FormattedMessage
                  id='view.settings.api_key_generated_at'
                  defaultMessage='Generated at {generatedAt}'
                  values={{ generatedAt }}
                />
              </Typography>
            </Grid>
          )}

          {/* <Grid item marginTop={2}>
            <SubHeadlineRegular
              textContent={formatMessage({
                id: 'view.settings.api_documentation_title',
                defaultMessage: 'API Documentation',
              })}
            />

            <Typography variant='body2'>
              <FormattedMessage
                id='view.settings.api_documentation_description'
                defaultMessage='Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.'
              />
            </Typography>
          </Grid>
           <Grid item>
            <Button
              disabled
              onClick={() => {
                alert('Download API documentation not implemented yet');
              }}
              icon={<Download />}
              variant='outlined'>
              <FormattedMessage
                id='view.settings.api_documentation_button'
                defaultMessage='Download API documentation'
              />
            </Button>
          </Grid> */}
        </Grid>
      </ContentContainer>
    </>
  );
};

export default Settings;
