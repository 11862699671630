import { SettingsOutlined } from '@mui/icons-material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import { Backdrop, Box, Collapse, Drawer, Fade, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import {
  getCurrentCoursesPathname,
  getDashboardPathname,
  getExpiredCoursesPathname,
  getStatisticsPages,
} from '@/Mappers';
import { useCurrentUserRoleQuery, useFaqListQuery } from '@/Queries';
import MenuIcon from '@/Static/Icons/atoms-symbols-icons-menu.svg';
import { Role } from '@/Types';

import { MenuNavigationItem } from '../MenuNavigationItem';
import MenuNavItemHeader from '../MenuNavigationItem/MenuNavItemHeader';
import { useSidebarNavigationStyles } from '../Styles/SidebarNavigation.styles';
import SidebarLogo from './SidebarLogo';

export const SidebarNavigation = () => {
  const { classes } = useSidebarNavigationStyles();
  const location = useLocation();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const currentLanguage = useCurrentLanguage();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const { data } = useFaqListQuery();
  const categoryFAQs = useMemo(() => data?.data?.categoryFaqs || [], [data]);

  const handleToggleSidebar = useCallback(() => {
    const body = document.querySelector('body');
    if (body && !open) {
      body.style.overflowY = 'hidden';
    } else if (body && open) {
      body.style.overflowY = 'unset';
    }
    setOpen(!open);
  }, [open]);

  const { data: userRole } = useCurrentUserRoleQuery();

  const activeMainNavItem = useMemo(() => location.pathname.split('/')[2], [location.pathname]);

  useEffect(() => {
    if (
      activeMainNavItem === 'allgemeine-informationen' ||
      activeMainNavItem === 'beschreibung-und-oeffnungszeiten' ||
      activeMainNavItem === 'ausstattung-und-leistungen' ||
      activeMainNavItem === 'foto-und-video' ||
      activeMainNavItem === 'current-checkins'
    ) {
      setExpandedSection({
        studioProfile: true,
      });
    }

    if (activeMainNavItem === 'statistik') {
      setExpandedSection({
        statistik: true,
      });
    }

    if (
      activeMainNavItem === 'kursuebersicht' ||
      activeMainNavItem === 'terminplan' ||
      activeMainNavItem === 'kurse' ||
      activeMainNavItem === 'neuen-kurs-anlegen'
    ) {
      setExpandedSection({
        kursuebersicht: true,
      });
    }

    if (activeMainNavItem === 'benutzer') {
      setExpandedSection({
        benutzer: true,
      });
    }
    if (activeMainNavItem === 'marketings-materialien') {
      setExpandedSection({
        marketingsMaterialien: true,
      });
    }
    if (activeMainNavItem === 'unterstutzung') {
      setExpandedSection({
        unterstutzung: true,
      });
    }
    if (activeMainNavItem === 'einstellungen') {
      setExpandedSection({
        einstellungen: true,
      });
    }
  }, [activeMainNavItem]);

  const [expandedSection, setExpandedSection] = useState<Record<string, boolean>>({});

  const toggleSection = useCallback((sectionKey: string) => {
    setExpandedSection(prev => ({
      ...prev,
      [sectionKey]: !prev[sectionKey],
    }));
  }, []);

  return (
    <div className={classes.root}>
      <Backdrop
        open={open && !matches}
        className={classes.backdrop}
        onClick={handleToggleSidebar}
      />
      <Drawer
        classes={{
          paper: clsx(classes.paper, {
            [classes.paperOpen]: open,
            [classes.paperClose]: !open,
          }),
        }}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        variant='permanent'>
        <Link
          className={clsx(classes.brandContainer, { [classes.alignLeft]: open })}
          to={getDashboardPathname(currentLanguage)}>
          <Box className={clsx(classes.logo, { [classes.logoFullSize]: open })} />
          <Fade in={open || matches} timeout={800}>
            <Box
              className={clsx(classes.brandWrapper, {
                [classes.brandContainerHide]: !open && !matches,
              })}>
              <SidebarLogo />
            </Box>
          </Fade>
        </Link>
        <Fade in={!open && !matches}>
          <Box
            className={clsx(classes.menuIconWrapper, {
              [classes.hidden]: open || matches,
            })}
            onClick={handleToggleSidebar}>
            <img alt='' src={MenuIcon} className={classes.menuIcon} />
          </Box>
        </Fade>
        <Fade in={open || matches} timeout={200}>
          <Box className={classes.menuContainer}>
            <Box className={classes.itemsWrapper}>
              <Box className={clsx({ [classes.hidden]: !open && !matches })}>
                <Box className={classes.navigationList}>
                  <MenuNavItemHeader
                    isActive={expandedSection.studioProfile}
                    menuIcon={<HomeOutlinedIcon />}
                    title={intl.formatMessage({
                      id: 'view.side_navigation.category.my_studio_profile',
                      defaultMessage: 'Studio profile',
                    })}
                    onClick={() => toggleSection('studioProfile')}
                    isExpandable
                  />
                  <Collapse in={expandedSection.studioProfile}>
                    <MenuNavigationItem
                      isGroupHeader
                      title={intl.formatMessage({
                        id: 'view.common_information',
                        defaultMessage: 'General information',
                      })}
                      href={`/${currentLanguage}/allgemeine-informationen/adresse-und-kontakt`}
                    />
                    <Collapse in={activeMainNavItem === 'allgemeine-informationen'}>
                      <MenuNavigationItem
                        title={intl.formatMessage({
                          id: 'view.common_information.address_and_contact',
                          defaultMessage: 'Address & Contact',
                        })}
                        href={`/${currentLanguage}/allgemeine-informationen/adresse-und-kontakt`}
                        navLevel='nav-item'
                      />
                      <MenuNavigationItem
                        title={intl.formatMessage({
                          id: 'view.common_information.check_in_options',
                          defaultMessage: 'Check-in options',
                        })}
                        href={`/${currentLanguage}/allgemeine-informationen/check-in-optionen`}
                        navLevel='nav-item'
                      />
                      <MenuNavigationItem
                        title={intl.formatMessage({
                          id: 'view.common_information.categories',
                          defaultMessage: 'Categories',
                        })}
                        href={`/${currentLanguage}/allgemeine-informationen/kategorien`}
                        navLevel='nav-item'
                      />
                    </Collapse>

                    <MenuNavigationItem
                      isGroupHeader
                      title={intl.formatMessage({
                        id: 'view.description_and_opening_hours',
                        defaultMessage: 'Description & opening hours',
                      })}
                      href={`/${currentLanguage}/beschreibung-und-oeffnungszeiten/beschreibung`}
                    />
                    <Collapse in={activeMainNavItem === 'beschreibung-und-oeffnungszeiten'}>
                      <MenuNavigationItem
                        title={intl.formatMessage({
                          id: 'view.description_and_opening_hours.description',
                          defaultMessage: 'Description',
                        })}
                        href={`/${currentLanguage}/beschreibung-und-oeffnungszeiten/beschreibung`}
                        navLevel='nav-item'
                      />

                      <MenuNavigationItem
                        title={intl.formatMessage({
                          id: 'view.description_and_opening_hours.opening_hours',
                          defaultMessage: 'Opening hours',
                        })}
                        href={`/${currentLanguage}/beschreibung-und-oeffnungszeiten/oeffnungszeiten`}
                        navLevel='nav-item'
                      />
                    </Collapse>
                    <MenuNavigationItem
                      title={intl.formatMessage({
                        id: 'view.equipment_and_services',
                        defaultMessage: 'Equipment & Services',
                      })}
                      href={`/${currentLanguage}/ausstattung-und-leistungen`}
                    />
                    <MenuNavigationItem
                      isGroupHeader
                      title={intl.formatMessage({
                        id: 'view.photo_and_video',
                        defaultMessage: 'Image gallery',
                      })}
                      href={`/${currentLanguage}/foto-und-video/logo`}
                    />
                    <Collapse in={activeMainNavItem === 'foto-und-video'}>
                      <MenuNavigationItem
                        title={intl.formatMessage({
                          id: 'view.photo_and_video.logo',
                          defaultMessage: 'Logo',
                        })}
                        href={`/${currentLanguage}/foto-und-video/logo`}
                        navLevel='nav-item'
                      />
                      <MenuNavigationItem
                        title={intl.formatMessage({
                          id: 'view.photo_and_video.photo_upload',
                          defaultMessage: 'Photo upload',
                        })}
                        href={`/${currentLanguage}/foto-und-video/foto-upload`}
                        navLevel='nav-item'
                      />

                      <MenuNavigationItem
                        title={intl.formatMessage({
                          id: 'view.photo_and_video.video_upload',
                          defaultMessage: 'Video Upload',
                        })}
                        href={`/${currentLanguage}/foto-und-video/video-upload`}
                        navLevel='nav-item'
                      />
                    </Collapse>

                    <MenuNavigationItem
                      title={intl.formatMessage({
                        id: 'view.current_checkins',
                        defaultMessage: 'Current Check-Ins',
                      })}
                      href={`/${currentLanguage}/current-checkins`}
                    />
                  </Collapse>
                </Box>
              </Box>
              <Box className={classes.navigationList}>
                <MenuNavItemHeader
                  isActive={expandedSection.statistik}
                  menuIcon={<QueryStatsIcon />}
                  title={intl.formatMessage({
                    id: 'view.side_navigation.category.statistic',
                    defaultMessage: 'Statistik',
                  })}
                  onClick={() => toggleSection('statistik')}
                  isExpandable
                />
                <Collapse in={expandedSection.statistik}>
                  {getStatisticsPages(intl).map(page => (
                    <MenuNavigationItem
                      key={page.path}
                      title={page.title}
                      href={`/${currentLanguage}/statistik/${page.path}`}
                    />
                  ))}
                </Collapse>
              </Box>
              <Box className={classes.navigationList}>
                <MenuNavItemHeader
                  isActive={expandedSection.kursuebersicht}
                  menuIcon={<DateRangeIcon />}
                  title={intl.formatMessage({
                    id: 'view.side_navigation.category.my_course_manager',
                    defaultMessage: 'Course Manager',
                  })}
                  onClick={() => toggleSection('kursuebersicht')}
                  isExpandable
                />
                <Collapse in={expandedSection.kursuebersicht}>
                  <MenuNavigationItem
                    isGroupHeader
                    title={intl.formatMessage({
                      id: 'view.courses.courses_overview',
                      defaultMessage: 'Course overview',
                    })}
                    href={`/${currentLanguage}/kursuebersicht/laufende-kurse`}
                  />
                  <Collapse in={activeMainNavItem === 'kursuebersicht'}>
                    <MenuNavigationItem
                      title={intl.formatMessage({
                        id: 'view.courses_and_events.courses',
                        defaultMessage: 'Active courses',
                      })}
                      href={getCurrentCoursesPathname(currentLanguage)}
                      navLevel='nav-item'
                    />
                    <MenuNavigationItem
                      title={intl.formatMessage({
                        id: 'view.courses_and_events.past_courses',
                        defaultMessage: 'Inactive courses & drafts',
                      })}
                      href={getExpiredCoursesPathname(currentLanguage)}
                      navLevel='nav-item'
                    />
                  </Collapse>
                  <MenuNavigationItem
                    title={intl.formatMessage({
                      id: 'view.courses_and_dates',
                      defaultMessage: 'Schedule',
                    })}
                    href={`/${currentLanguage}/terminplan`}
                  />
                </Collapse>
                {userRole === Role.ADMIN && (
                  <Box className={classes.navigationList}>
                    <MenuNavItemHeader
                      isActive={expandedSection.benutzer}
                      menuIcon={<PersonOutlineOutlinedIcon />}
                      title={intl.formatMessage({
                        id: 'view.user_management.users',
                        defaultMessage: 'User',
                      })}
                      onClick={() => toggleSection('benutzer')}
                      isExpandable
                    />
                    <Collapse in={expandedSection.benutzer}>
                      <MenuNavigationItem
                        title={intl.formatMessage({
                          id: 'view.user_management.user_management',
                          defaultMessage: 'User management',
                        })}
                        href={`/${currentLanguage}/benutzer/benutzerverwaltung`}
                      />
                      <MenuNavigationItem
                        title={intl.formatMessage({
                          id: 'view.user_management.invite_users',
                          defaultMessage: 'Invite users',
                        })}
                        href={`/${currentLanguage}/benutzer/benutzer-einladen`}
                      />
                    </Collapse>
                  </Box>
                )}
                <MenuNavItemHeader
                  isActive={expandedSection.marketingsMaterialien}
                  menuIcon={<FolderOutlinedIcon />}
                  title={intl.formatMessage({
                    id: 'marketing_material.title',
                    defaultMessage: 'Downloads',
                  })}
                  isExpandable={false}
                  href={`/${currentLanguage}/marketings-materialien`}
                />
                {userRole === Role.ADMIN && (
                  <MenuNavItemHeader
                    isActive={expandedSection.einstellungen}
                    menuIcon={<SettingsOutlined />}
                    title={intl.formatMessage({
                      id: 'view.settings',
                      defaultMessage: 'Settings',
                    })}
                    isExpandable={false}
                    href={`/${currentLanguage}/einstellungen`}
                  />
                )}
                {categoryFAQs.length > 0 && (
                  <MenuNavItemHeader
                    isActive={expandedSection.unterstutzung}
                    menuIcon={<SupportOutlinedIcon />}
                    title={intl.formatMessage({
                      id: 'marketing_material.support',
                      defaultMessage: 'Support & Contact',
                    })}
                    isExpandable={false}
                    href={`/${currentLanguage}/unterstutzung`}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Drawer>
    </div>
  );
};
