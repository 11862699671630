import 'moment/locale/de';

import { Box, Button, TableBody, TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { useCallback } from 'react';

import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { EventsService } from '@/Services';
import { Course, EventListItem, Partner } from '@/Types';

import { useTableBodyStyles } from './PastEvents.styles';

export interface PastEventListTableBodyProps {
  casPublicId: Partner['casPublicId'];
  course: Course;
  eventsService: EventsService;
  events: EventListItem[];
  handleErrorMessage: (message: string) => void;
}

export const PastEventListTableBody = ({
  events,
  casPublicId,
  eventsService,
  handleErrorMessage,
}: PastEventListTableBodyProps) => {
  const { classes } = useTableBodyStyles();
  const { formatMessage } = useMemoedIntl();

  const downloadBookingsList = useCallback(
    async (event: EventListItem) => {
      const response = await eventsService.getBookings(
        casPublicId,
        event.courseId,
        event.eventId.toString(),
      );

      const firstNameLabel = formatMessage({
        id: 'view.statistic.check_in_all.table.header.first_name',
        defaultMessage: 'First name',
      });
      const lastNameLabel = formatMessage({
        id: 'view.statistic.check_in_all.table.header.last_name',
        defaultMessage: 'Last name',
      });
      const companyNameLabel = formatMessage({
        id: 'view.statistic.check_in_all.table.header.company_name',
        defaultMessage: 'Company name',
      });

      if (response.data && response.data.length > 0) {
        const dataToExport = response.data.map(booking => ({
          [firstNameLabel]: booking.firstname,
          [lastNameLabel]: booking.lastname,
          [companyNameLabel]: booking.company,
        }));

        const doc = new jsPDF();
        autoTable(doc, {
          head: [Object.keys(dataToExport[0])],
          body: dataToExport.map(row => Object.values(row)),
          theme: 'striped',
        });
        doc.save(
          `${event.internalCourseName}_${event.date?.toString().replace('-', '')}_Anmeldungen.pdf`,
        );
      } else {
        handleErrorMessage(
          formatMessage({
            id: 'view.courses_and_events.past_events.table.no_bookings',
            defaultMessage: 'No bookings found',
          }),
        );
      }
    },
    [casPublicId, eventsService, formatMessage, handleErrorMessage],
  );

  return (
    <TableBody className={classes.root}>
      {events.map((item: EventListItem, idx: number) => (
        <TableRow className={classes.tableRow} key={item.eventId}>
          <TableCell className={classes.tableCell}>
            <Box
              className={clsx(
                {
                  [classes.tableCellContainerFirstRow]: idx === 0,
                  [classes.tableCellContainerOdd]: idx % 2 !== 0,
                },
                classes.tableCellContainer,
                classes.tableCellContainerFirst,
                classes.tableCellContainerFirstRound,
              )}>
              {moment(item.date).locale('de').format('dd')}, {moment(item.date).format('DD.MM.')}
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Box
              className={clsx(
                {
                  [classes.tableCellContainerFirstRow]: idx === 0,
                  [classes.tableCellContainerOdd]: idx % 2 !== 0,
                },
                classes.tableCellContainer,
              )}>
              {item.time} {formatMessage({ id: 'time', defaultMessage: 'Clock' })}
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Box
              className={clsx(
                {
                  [classes.tableCellContainerFirstRow]: idx === 0,
                  [classes.tableCellContainerOdd]: idx % 2 !== 0,
                },
                classes.tableCellContainer,
              )}>
              {item.duration}{' '}
              {formatMessage({
                id: 'view.courses_and_events.past_events.table.minutes',
                defaultMessage: 'min',
              })}
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Box
              className={clsx(
                'participantsText',
                {
                  [classes.tableCellContainerFirstRow]: idx === 0,
                  [classes.tableCellContainerOdd]: idx % 2 !== 0,
                },
                classes.tableCellContainer,
                classes.tableCellContainerUtil,
                'tableCellContainerUtil',
              )}>
              {item.checkins} / {item.capacity}
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Box
              className={clsx(
                {
                  [classes.tableCellContainerFirstRow]: idx === 0,
                  [classes.tableCellContainerOdd]: idx % 2 !== 0,
                },
                classes.tableCellContainer,
                classes.tableCellContainerLastRound,
                classes.tableCellContainerStatus,
              )}>
              {item.cancelled
                ? formatMessage({
                    id: 'view.courses_and_events.past_events.table.state.canceled',
                    defaultMessage: 'cancelled',
                  })
                : formatMessage({
                    id: 'view.courses_and_events.past_events.table.state.successful',
                    defaultMessage: 'successful',
                  })}
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Box
              className={clsx(
                {
                  [classes.tableCellContainerFirstRow]: idx === 0,
                  [classes.tableCellContainerOdd]: idx % 2 !== 0,
                },
                classes.tableCellContainer,
                classes.tableCellContainerRound,
                classes.tableCellContainerLast,
                'tableCellContainerParticipants',
              )}>
              <Button
                disabled={item.checkins === 0}
                onClick={() => downloadBookingsList(item)}
                variant='text'
                color='primary'
                className={clsx(classes.downloadBtn, 'downloadBtn')}>
                {formatMessage({
                  id: 'view.courses_and_events.past_events.table.download',
                  defaultMessage: 'Download',
                })}
              </Button>
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
