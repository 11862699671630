import { GridFilterModel } from '@mui/x-data-grid-pro';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormSnackbar } from '@/App/Shared/Notification/Components/FormSnackbar';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import { getCoursesPathname } from '@/Mappers';
import { useGetPartnerCoursesQuery } from '@/Queries';
import InfoCourseIcon from '@/Static/Icons/course_delay_icon.svg';
import { Category, EventsData, Partner } from '@/Types';

import { CoursesTable } from './Components/Table/CoursesTable';
import Container from './Courses.styles';

type CoursesProps = {
  loading: boolean;
  casPublicId: Partner['casPublicId'];
  categories: Category[];
  isActiveList: boolean;
};

const Courses = ({ casPublicId, categories, loading, isActiveList }: CoursesProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const [openToastMessage, setOpenToastMessage] = useState(false);
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const currentLanguage = useCurrentLanguage();

  const [paginationState, setPaginationState] = useState<EventsData['pagination']>({
    totalCount: 0,
    itemsPerPage: 10,
    pages: 0,
    currentPage: 1,
  });

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [debouncedFilterModel, setDebouncedFilterModel] = useState<GridFilterModel>(filterModel);

  // Debounce the filter changes
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilterModel(filterModel);
    }, 300); // Adjust the debounce delay as needed

    return () => clearTimeout(handler); // Cleanup the timeout on unmount or model change
  }, [filterModel]);

  const {
    data,
    isLoading: isLoadingCourses,
    isSuccess,
  } = useGetPartnerCoursesQuery({
    casPublicId,
    isActiveCourse: isActiveList,
    page: paginationState.currentPage,
    limit: paginationState.itemsPerPage,
    filter: debouncedFilterModel,
  });

  useEffect(() => {
    if (data && isSuccess && !isLoadingCourses) {
      setPaginationState(data.pagination);
    }
  }, [data, isLoadingCourses, isSuccess]);

  const handleToastMessage = useCallback((value: boolean) => {
    setOpenToastMessage(value);
  }, []);

  useEffect(() => {
    if (queryParams.success === 'show') {
      setOpenToastMessage(true);

      navigate(getCoursesPathname(currentLanguage));
    }
  }, [currentLanguage, navigate, queryParams.success]);

  return (
    <Container>
      <FormSnackbar
        openToastMessage={openToastMessage}
        handleCloseToastMessage={() => handleToastMessage(false)}
        customMessage={intl.formatMessage({
          id: 'toast.save.course',
          defaultMessage:
            'Deine Änderungen werden innerhalb der nächsten 10 min. in der App und im Web sichtbar.',
        })}
        customIcon={InfoCourseIcon}
      />
      <CoursesTable
        setPaginationState={setPaginationState}
        paginationState={paginationState}
        courses={data?.result || []}
        categories={categories}
        // showCourseSearch
        isPastCourse={!isActiveList}
        loading={loading || isLoadingCourses}
        filtersState={debouncedFilterModel}
        setFiltersState={setFilterModel}
      />
    </Container>
  );
};

export default Courses;
