import moment from 'moment/moment';
import { useEffect, useState } from 'react';

import { EventListItem } from '@/Types';

import useCurrentLanguage from './useCurrentLanguage';

export const useEventsModalHelper = (eventIds: string[], events: EventListItem[]) => {
  const [subHeader, setSubHeader] = useState('');
  const [selectedEvents, setSelectedEvents] = useState<EventListItem[]>([]);
  const [hasPublishedEvents, setHasPublishedEvents] = useState(false);
  const [hasEventsWithBookings, setHasEventsWithBookings] = useState(false);
  const currentLanguage = useCurrentLanguage();

  useEffect(() => {
    const filteredEvents = events.filter(
      event => eventIds.indexOf(event.eventId.toString()) !== -1,
    );

    const eventsWithBookings = filteredEvents.filter(event => event.bookings > 0);
    const publishedEvents = filteredEvents.filter(event => event.published);

    setHasEventsWithBookings(eventsWithBookings.length > 0);
    setHasPublishedEvents(publishedEvents.length > 0);

    if (filteredEvents.length === 1) {
      const formattedSubHeader = moment(filteredEvents[0].date + ' ' + filteredEvents[0].time)
        .locale(currentLanguage)
        .format('LLLL');

      setSubHeader(formattedSubHeader);
    } else {
      const formattedSubheader = filteredEvents
        .map(event => moment(event.date).locale(currentLanguage).format('LL'))
        .join(', ');

      setSubHeader(formattedSubheader);
    }

    setSelectedEvents(filteredEvents);
  }, [currentLanguage, eventIds, events]);

  return { subHeader, selectedEvents, hasPublishedEvents, hasEventsWithBookings };
};
