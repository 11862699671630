import { Button, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { CustomDataGrid } from '@/App/Shared/DataGridTable/Table/DataGridTable';
import { NoResultsOverlay } from '@/App/Shared/DataGridTable/Table/NoResultsOverlay';
import { NoRowsOverlay } from '@/App/Shared/DataGridTable/Table/NoRowsOverlay';
import { CheckedInCheckedOutStatus, Header } from '@/Components';
import useCurrentCheckinsBreadcrumbs from '@/Hooks/Breadcrumbs/useCurrentCheckinsBreadcrumbs';
import useCurrentCheckins from '@/Hooks/useCurrentCheckins';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { useGetPartnerQuery } from '@/Queries';
import { checkInManually, checkOutManually } from '@/Services/CheckInManually.service';
import { uiActions } from '@/Store';
import { CheckInCheckoutEventType, ManualCheckInputType, ManualCheckOutPayloadType } from '@/Types';
import { checkInSorter, checkInStatusHelper, combineStrings } from '@/Utils';
import handleCatchError from '@/Utils/handleCatchError';

import FigmaDesignTokens from '../../design/design-tokens.json';
import {
  Container,
  Wrapper,
} from '../Users/Components/AdministrationTable/AdministrationTable.styles';
import useStyles from './CurrentCheckins.style';
import Toolbar from './Toolbar';

const CurrentCheckins = () => {
  const { formatMessage } = useMemoedIntl();
  const { classes } = useStyles()();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: partnerData } = useGetPartnerQuery();
  const { data: rows = [], isLoading: isLoadingData } = useCurrentCheckins();
  const currentLanguage = useCurrentLanguage();
  const currentBreadcrumbs = useCurrentCheckinsBreadcrumbs();

  const queryClient = useQueryClient();

  const sortedRows = useMemo(() => [...rows].sort(checkInSorter), [rows]);

  const manualCheckoutAction = useCallback(
    async ({ euId, partnerPublicId, referenceId }: ManualCheckOutPayloadType) => {
      try {
        const response = await checkOutManually({
          euId,
          partnerPublicId,
          referenceId,
        });

        queryClient.invalidateQueries(['currentCheckins', partnerPublicId]);

        return response;
      } catch (e) {
        handleCatchError(e);
      }
    },
    [queryClient],
  );

  const columns: GridColDef<CheckInCheckoutEventType>[] = useMemo(
    () => [
      {
        field: 'checkInId',

        valueGetter: (value, row) => row.data.checkInId,
      },
      {
        field: 'Avatar',
        filterable: false,
        headerName: '',
        width: 80,
        pinnable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: params => (
          <div className={classes.avatarContainer}>
            <img src={params.row.data.euPictureUrl} className={classes.avatar} alt='' />
          </div>
        ),
      },
      {
        field: 'Name',
        headerName: formatMessage({
          id: 'view.current_checkins.table.header.name',
          defaultMessage: 'Name',
        }),
        flex: 1,
        renderCell: params => <div style={{ fontWeight: 'bold' }}>{params.value}</div>,
        valueGetter: (value, row) => combineStrings(row.data.firstname, row.data.lastname),
      },
      // {
      //   field: 'dateOfBirth',
      //   headerName: formatMessage({
      //     id: 'view.current_checkins.table.header.birthday',
      //     defaultMessage: 'Birthday',
      //   }),
      //   width: 170,
      //   renderCell: params =>
      //     params.row.data.dateOfBirth ? (
      //       <FormattedDate value={params.row.data.dateOfBirth} />
      //     ) : null,
      // },
      {
        field: 'company',
        headerName: formatMessage({
          id: 'view.current_checkins.table.header.company',
          defaultMessage: 'Company',
        }),
        flex: 1,
        valueGetter: (value, row) => row.data.company,
      },
      // {
      //   field: 'services',
      //   headerName: formatMessage({
      //     id: 'view.current_checkins.table.header.services',
      //     defaultMessage: 'Services',
      //   }),
      //   flex: 1,
      //   renderCell: params => {
      //     if (Array.isArray(params.row.data.services)) {
      //       return params.row.data.services
      //         .filter(f => f.isStandard)
      //         .map(service => <ServicesBadge service={service.name} key={service.name} />);
      //     }

      //     return (
      //       <ServicesBadge service={params.row.data.services} key={params.row.data.services} />
      //     );
      //   },
      // },
      {
        field: 'checkinCheckout',
        headerName: formatMessage({
          id: 'view.current_checkins.table.header.checkin_checkout',
          defaultMessage: 'Check-In/Check-Out',
        }),
        flex: 1,
        renderCell: params => (
          <CheckedInCheckedOutStatus
            checkInTimestamp={params.row.data.checkInTimestamp}
            checkOutTimestamp={params.row.data.checkOutTimestamp}
            checkInSource={params.row.data.checkInSource}
            checkOutSource={params.row.data.checkOutSource}
            offline={params.row.data.offline}
          />
        ),
        valueGetter: (value, row) => {
          if (row.name === 'checkIn') {
            return row.data.checkInTimestamp;
          }

          if (row.name === 'checkOut') {
            return row.data.checkOutTimestamp;
          }

          return null;
        },
      },

      {
        field: 'checkinCheckoutCta',
        headerName: '',
        width: 120,
        renderCell: (params: GridRenderCellParams<CheckInCheckoutEventType>) => {
          if (params.row.data.checkOutTimestamp) {
            return null;
          }

          return (
            <div>
              <Button
                variant='outlined'
                onClick={() => {
                  manualCheckoutAction({
                    euId: params.row.data.euId,
                    partnerPublicId: params.row.data.partnerPublicId,
                    referenceId: params.row.data.referenceId,
                  });
                }}
                className={classes.checkoutButton}>
                <FormattedMessage
                  id='view.current_checkins.table.cta.force_checkout'
                  defaultMessage='Check-Out'
                />
              </Button>
            </div>
          );
        },
      },
    ],
    [
      classes.avatar,
      classes.avatarContainer,
      classes.checkoutButton,
      formatMessage,
      manualCheckoutAction,
    ],
  );

  const handleCheckInSubmit = useCallback(
    async (data: ManualCheckInputType) => {
      if (!partnerData?.publicId) return;

      const referenceId = uuidv4();

      try {
        const response = await checkInManually({
          ...data,
          partnerPublicId: partnerData?.publicId || '',
          referenceId,
        });

        queryClient.invalidateQueries(['currentCheckins', partnerData?.publicId]);
        return response;
      } catch (e) {
        throw e;
      }
    },
    [partnerData, queryClient],
  );

  const showModalPaymentMethod = useCallback(() => {
    dispatch(
      uiActions.showModal('CHECK_IN', {
        handleSubmit: (data: ManualCheckInputType) => handleCheckInSubmit(data),
      }),
    );
  }, [dispatch, handleCheckInSubmit]);

  const handleNewCourseForm = useCallback(() => {
    navigate(`/${currentLanguage}/statistik/check-ins-alle`);
  }, [navigate, currentLanguage]);

  return (
    <>
      <Header
        showLanguageSwitcher={false}
        header={formatMessage({
          id: 'view.current_checkins',
          defaultMessage: 'Current Check-Ins',
        })}
        breadcrumbs={currentBreadcrumbs}
      />

      <Container>
        <Wrapper>
          <CustomDataGrid
            slots={{
              noResultsOverlay: () => (
                <NoResultsOverlay
                  header={formatMessage({
                    id: 'view.current_checkins.table.empty_filter_header',
                    defaultMessage: 'No Check-Ins found',
                  })}
                  subHeader={formatMessage({
                    id: 'view.current_checkins.table.empty_filter_sub_header',
                    defaultMessage: 'Please change your search criteria and try again.',
                  })}
                />
              ),
              noRowsOverlay: () => (
                <NoRowsOverlay
                  header={formatMessage({
                    id: 'view.current_checkins.table.empty_table_header',
                    defaultMessage: 'No existing check-ins',
                  })}
                  subHeader={formatMessage({
                    id: 'view.current_checkins.table.empty_table_sub_header',
                    defaultMessage: 'Only current check-ins are shown here.',
                  })}
                  ctaTitle={formatMessage({
                    id: 'view.current_checkins.cta.link_to_statistics',
                    defaultMessage: 'Show Statistics',
                  })}
                  readOnlyPartner={partnerData?.readonly}
                  ctaHandler={handleNewCourseForm}
                  hasIcon={false}
                />
              ),
              toolbar: () => (
                <Toolbar
                  label={formatMessage({
                    id: 'view.current_checkins.table.header.label_today',
                    defaultMessage: 'All Check-Ins of Today',
                  })}
                  handleSubmit={showModalPaymentMethod}
                  submitText={formatMessage({
                    id: 'view.current_checkins.cta.add_manual_checkin',
                    defaultMessage: 'Add Manual Check-In',
                  })}
                />
              ),
            }}
            loading={isLoadingData}
            columns={columns as GridColDef<GridValidRowModel>[]}
            rows={sortedRows}
            sx={{
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator--sideRight': {
                display: 'none',
              },
              '& .MuiDataGrid-cell': {
                fontSize: 14,
                lineHeight: 1.4,
              },
              '& .MuiDataGrid-columnHeader:nth-of-type(5) .MuiDataGrid-columnSeparator--sideRight':
                {
                  display: 'none',
                },

              marginTop: '40px',
              backgroundColor: FigmaDesignTokens.White,
              '& .active': {
                backgroundColor: theme.palette.success.light,
              },
              '& .offline': {
                backgroundColor: FigmaDesignTokens.Orange[50],
              },
            }}
            getRowId={row => row.data.checkInId}
            pagination
            getRowClassName={params => checkInStatusHelper(params.row.data)}
            isRowSelectable={() => false}
            rowHeight={68}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  checkInId: false,
                },
              },
            }}
            columnHeaderHeight={48}
          />
        </Wrapper>
      </Container>
    </>
  );
};

export default CurrentCheckins;
