import { Box, Grid } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { FormSnackbar } from '@/App/Shared/Notification/Components/FormSnackbar';
import { SpinnerLoader } from '@/Components';
import { useAppContext } from '@/Context';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { getDefaultFiltersValues } from '@/Models';
import { EventsServiceFactory } from '@/Services';
import ErrorIcon from '@/Static/Icons/atoms-symbols-alert-error.svg';
import { EventsData, FilterParam, Partner } from '@/Types';

import { ContentContainer } from '../../../../App/Shared/ContentContainer/ContentContainer';
import { FormHeader } from '../../../../App/Shared/Form/Components/Header/FormHeader';
import { CustomTable } from '../../../../App/Shared/Table/CustomTable';
import { ListTableFooter } from '../../../../App/Shared/Table/ListTableFooter';
import { PastEventListTableBody } from './PastEventListTableBody';
import { PastEventListTableHead } from './PastEventListTableHead';
import { usePastEventListStyles } from './PastEvents.styles';

type PastEventListProps = {
  courseId: number;
  casPublicId: Partner['casPublicId'];
};

export const PastEventList = ({ casPublicId, courseId }: PastEventListProps) => {
  const { classes } = usePastEventListStyles();
  const {
    state: {
      courseManager: { course },
    },
    dispatch,
  } = useAppContext();
  const intl = useIntl();
  const currentLanguage = useCurrentLanguage();
  const [eventsData, setEventsData] = useState<EventsData>();
  const [isLoading, setIsLoading] = useState(false);
  const [toastMessageContent, setToastMessageContent] = useState('');

  const eventsService = useMemo(() => new EventsServiceFactory().getInstance(dispatch), [dispatch]);
  const defaultFilterValues = useMemo(
    () => ({ ...getDefaultFiltersValues(), page: '1', limit: 10 }),
    [],
  );

  const getEventsData = useCallback(async () => {
    setIsLoading(true);

    const response = await eventsService.getEvents(
      casPublicId,
      'ARCHIVED',
      eventsService.getFiltersParams(defaultFilterValues, courseId),
    );
    setEventsData(response.data);

    setIsLoading(false);
  }, [casPublicId, courseId, defaultFilterValues, eventsService]);

  useEffect(() => {
    getEventsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePage = useCallback(
    async (page: number) => {
      setIsLoading(true);

      const filtersParam: FilterParam[] = eventsService.getFiltersParams(
        {
          ...defaultFilterValues,
          page: page.toString(),
        },
        courseId,
      );

      const response = await eventsService.getEvents(casPublicId, 'ARCHIVED', filtersParam);
      setEventsData(response.data);

      setIsLoading(false);
    },
    [casPublicId, courseId, defaultFilterValues, eventsService],
  );

  const handleCloseToastMessage = useCallback(() => {
    setToastMessageContent('');
  }, []);
  const handleErrorMessage = useCallback((message: string) => {
    setToastMessageContent(message);
  }, []);

  if (!course || !eventsData) {
    return null;
  }

  return (
    <Box className={classes.contentContainerWrapper}>
      <FormSnackbar
        openToastMessage={!!toastMessageContent}
        handleCloseToastMessage={handleCloseToastMessage}
        customMessage={toastMessageContent}
        customIcon={ErrorIcon}
      />
      <ContentContainer>
        {isLoading && <SpinnerLoader />}
        <Grid container spacing={4}>
          <Grid item container xs={12} justifyContent='space-between'>
            <FormHeader
              title={`${course?.courseName[currentLanguage]} ${intl.formatMessage({
                id: 'view.courses_and_events.past_events.header',
                defaultMessage: '(History)',
              })}`}
            />
          </Grid>
          <Grid item container>
            <Grid item xs={12}>
              <CustomTable
                tableHead={<PastEventListTableHead />}
                tableBody={
                  <PastEventListTableBody
                    events={eventsData.result}
                    course={course}
                    casPublicId={casPublicId}
                    eventsService={eventsService}
                    handleErrorMessage={handleErrorMessage}
                  />
                }
                tableFooter={
                  eventsData.pagination?.pages > 0 ? (
                    <ListTableFooter
                      pages={eventsData.pagination.pages}
                      currentPage={eventsData.pagination.currentPage}
                      handlePage={handlePage}
                    />
                  ) : undefined
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </ContentContainer>
    </Box>
  );
};
