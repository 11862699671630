// This file contains the operators that are used in the server side filtering of the data grid.

import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid-pro';

export const STRING_OPERATORS = getGridStringOperators().filter(
  operator =>
    operator.value === 'contains' ||
    operator.value === 'equals' ||
    operator.value === 'doesNotEqual',
);

export const SELECT_OPERATORS = getGridSingleSelectOperators().filter(
  operator => operator.value === 'is' || operator.value === 'not',
);

export const NUMBER_OPERATORS = getGridNumericOperators().filter(
  operator =>
    operator.value === '=' ||
    operator.value === '!=' ||
    operator.value === '>=' ||
    operator.value === '<=' ||
    operator.value === '>' ||
    operator.value === '<',
);

export const DATE_OPERATORS = getGridDateOperators();
