import { Box, Grid, InputAdornment, MenuItem } from '@mui/material';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { InfoMessage, Map, MapNavigation } from '@/Components';
import { DelayModalTypes, useAppContext } from '@/Context';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import { CountryDialCodes } from '@/Models';
import { useUpdatePartnerDataMutation } from '@/Queries';
import { AddressAndContactPartialType, GeneralInformationFormsProps } from '@/Types';
import {
  AddressAndContactValidation,
  getFormattedCountryPhoneNumberPrefix,
  getFormattedPhoneNumber,
  getFormattedWebsite,
} from '@/Utils';
import handleCatchError from '@/Utils/handleCatchError';

import { useAddressAndContactFormStyles } from './AddressAndContactForm.styles';

export const AddressAndContactForm = ({
  refCallback,
  casPublicId,
  handlePrimaryCtaDisabled,
  setOpenToastMessage,
  partner,
}: GeneralInformationFormsProps) => {
  const { classes } = useAddressAndContactFormStyles();
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();
  const formikRef = useRef<FormikProps<AddressAndContactPartialType>>(null);
  const intl = useIntl();
  const { formatMessage } = intl;
  const handleConfirmDialog = useConfirmDialog();

  const initialState = useMemo<AddressAndContactPartialType>(
    () => ({
      street: partner.street,
      streetNumber: partner.streetNumber,
      zip: partner.zip,
      city: partner.city,
      country: partner.country,
      email: partner.email,
      phonenumber: getFormattedPhoneNumber(partner.phonenumber),
      countryPhoneNumberPrefix: getFormattedCountryPhoneNumberPrefix(partner.phonenumber),
      website: getFormattedWebsite(partner.website),
      coordLat: partner.coordLat,
      coordLong: partner.coordLong,
      studioName: partner.studioName,
    }),
    [partner],
  );
  const [geoChanged, setGeoChanged] = useState(false);

  const { mutateAsync: updatePartnerData } = useUpdatePartnerDataMutation();

  useEffect(() => {
    refCallback(formikRef, 0);
  }, [refCallback, formikRef]);

  const handleGeoChange = useCallback(
    (lat: number, lng: number) => {
      formikRef.current?.setFieldValue('coordLat', lat);
      formikRef.current?.setFieldValue('coordLong', lng);
      setGeoChanged(true);
      handleConfirmDialog(true);
      handlePrimaryCtaDisabled(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formikRef, handleConfirmDialog],
  );

  const handleWebsiteChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, formik: FormikProps<AddressAndContactPartialType>) => {
      const updatedString = e.currentTarget.value.replace('https://', '').replace('http://', '');
      formik.setFieldValue('website', updatedString);
    },
    [],
  );

  const handlePhoneNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, formik: FormikProps<AddressAndContactPartialType>) => {
      formik.setFieldValue('phonenumber', getFormattedPhoneNumber(e.currentTarget.value));
      formik.setFieldValue(
        'countryPhoneNumberPrefix',
        getFormattedCountryPhoneNumberPrefix(e.currentTarget.value),
      );
      e.preventDefault();
    },
    [],
  );

  const handleFormSubmission = async (data: AddressAndContactPartialType) => {
    if (!delayModal.notShowProfileModal) {
      dispatch({ type: DelayModalTypes.SET_OPEN, payload: { open: true } });
    }

    const updatedData = { ...data };
    updatedData.website = updatedData.website ? 'https://' + updatedData.website : '';
    updatedData.phonenumber = data.countryPhoneNumberPrefix + data.phonenumber;
    delete updatedData.countryPhoneNumberPrefix;

    try {
      await updatePartnerData({ casPublicId, updatedData });

      handlePrimaryCtaDisabled(true);
      handleConfirmDialog(false);
      setOpenToastMessage(true);
    } catch (error) {
      handleCatchError(error);
    }
  };

  // Trigger validation on language change
  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [intl.locale]); // Run when the language changes

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialState}
      validationSchema={() => AddressAndContactValidation(intl)}
      validate={values => {
        const initialStateStringify = JSON.stringify(initialState);
        const currentStateStringify = JSON.stringify(values);

        if (initialStateStringify !== currentStateStringify) {
          handleConfirmDialog(true);
          handlePrimaryCtaDisabled(false);
        }
      }}
      onSubmit={handleFormSubmission}>
      {formik => (
        <ContentContainer>
          <ConnectedFocusError />
          <Form>
            <Grid container spacing={4}>
              <Grid item container spacing={4}>
                <Grid item xs={12}>
                  <FormHeader
                    title={formatMessage({
                      id: 'form.address_and_contact.header.location',
                      defaultMessage: 'Location',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid xs={8} item container>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      component={FormikTextField}
                      variant='outlined'
                      name='studioName'
                      type='text'
                      label={
                        <FormattedMessage
                          id='form.address_and_contact.studio_name'
                          defaultMessage='Studio name'
                        />
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      component={FormikTextField}
                      variant='outlined'
                      name='street'
                      type='text'
                      label={
                        <FormattedMessage
                          id='form.address_and_contact.street'
                          defaultMessage='Street'
                        />
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={FormikTextField}
                      variant='outlined'
                      name='streetNumber'
                      type='text'
                      label={
                        <FormattedMessage
                          id='form.address_and_contact.house_number'
                          defaultMessage='House number'
                        />
                      }
                      disabled
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Field
                      component={FormikTextField}
                      variant='outlined'
                      name='zip'
                      type='text'
                      label={
                        <FormattedMessage
                          id='form.address_and_contact.zipcode'
                          defaultMessage='ZIP CODE'
                        />
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Field
                      component={FormikTextField}
                      variant='outlined'
                      name='city'
                      type='text'
                      label={
                        <FormattedMessage
                          id='form.address_and_contact.city'
                          defaultMessage='Location'
                        />
                      }
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      component={FormikTextField}
                      variant='outlined'
                      name='country'
                      type='text'
                      label={
                        <FormattedMessage
                          id='form.address_and_contact.country'
                          defaultMessage='Country'
                        />
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={4} item container>
                <Grid item xs={12}>
                  <InfoMessage
                    title={formatMessage({
                      id: 'form.address_and_contact.hint.adjustment.header',
                      defaultMessage: 'Inform us in case of data changes',
                    })}
                    description={formatMessage({
                      id: 'form.address_and_contact.hint.adjustment.description',
                      defaultMessage:
                        'If the name of your institution or the address changes, please contact our partner support so that we can change the data in the system.',
                    })}
                    type='information'
                  />
                </Grid>
              </Grid>

              <Grid xs={8} item>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <Map
                      geoChanged={geoChanged}
                      lat={formik.values.coordLat}
                      lng={formik.values.coordLong}
                      containerStyle={{ height: '200px', width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MapNavigation
                      lat={formik.values.coordLat}
                      lng={formik.values.coordLong}
                      handleChange={handleGeoChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={4} item>
                <Grid item xs={12}>
                  <InfoMessage
                    title={formatMessage({
                      id: 'form.address_and_contact.hint.marker_studio.header',
                      defaultMessage: 'Marking Studio Search',
                    })}
                    description={formatMessage({
                      id: 'form.address_and_contact.hint.marker_studio.description',
                      defaultMessage:
                        'This location is displayed for Hansefit members in the studio search. You can change the position of the pin. Just click on the arrows until the pin is in the right place.',
                    })}
                    type='information'
                  />
                </Grid>
              </Grid>

              <Grid item container>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FormHeader
                      title={formatMessage({
                        id: 'form.address_and_contact.header.contact',
                        defaultMessage: 'Contact details',
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={8} item>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      component={FormikTextField}
                      variant='outlined'
                      name='email'
                      type='text'
                      label={
                        <FormattedMessage
                          id='form.address_and_contact.email'
                          defaultMessage='E-mail address (contact)'
                        />
                      }
                      disabled={partner.readonly}
                    />
                  </Grid>
                  <Grid item xs={12} container>
                    <Box className={classes.phoneNumberContainer}>
                      <Field
                        component={FormikTextField}
                        label={
                          <FormattedMessage
                            id='form.address_and_contact.countryPhoneNumberPrefix'
                            defaultMessage='Prefix'
                          />
                        }
                        variant='outlined'
                        className={classes.countryPhoneNumberPrefix}
                        name='countryPhoneNumberPrefix'
                        disabled={partner.readonly}
                        select>
                        {CountryDialCodes.map(({ dialCode }) => (
                          <MenuItem key={dialCode} value={dialCode}>
                            {dialCode}
                          </MenuItem>
                        ))}
                      </Field>
                      <Field
                        className={classes.phoneNumber}
                        component={FormikTextField}
                        variant='outlined'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePhoneNumberChange(e, formik)
                        }
                        name='phonenumber'
                        type='text'
                        label={
                          <FormattedMessage
                            id='form.address_and_contact.phone'
                            defaultMessage='Phone number (contact)'
                          />
                        }
                        disabled={partner.readonly}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={FormikTextField}
                      variant='outlined'
                      name='website'
                      type='text'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleWebsiteChange(e, formik)
                      }
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>https://</InputAdornment>,
                      }}
                      label={
                        <FormattedMessage
                          id='form.address_and_contact.website'
                          defaultMessage='Website'
                        />
                      }
                      disabled={partner.readonly}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={4} item>
                <Grid item xs={12}>
                  <InfoMessage
                    title={formatMessage({
                      id: 'form.address_and_contact.hint.contact_data.header',
                      defaultMessage: 'Keep contact details up to date',
                    })}
                    description={formatMessage({
                      id: 'form.address_and_contact.hint.contact_data.description',
                      defaultMessage:
                        'The contact details are displayed to Hansefit members on your studio details page. Therefore, please make sure that the data is up to date.',
                    })}
                    type='information'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </ContentContainer>
      )}
    </Formik>
  );
};
