import { Box, FormControlLabel, TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import { Field, useFormikContext } from 'formik';
import React from 'react';

import { CustomizedRadio } from '@/App/Shared/Form/Components/Customized/CustomizedRadio';
import { FormikRadioGroup } from '@/App/Shared/Form/Components/Formik/FormikRadioGroup';
import { BodyTextSmall, LineDivider, SubHeadlineDisplay } from '@/Components';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { EquipmentsAndServicesPartial, ServiceItem } from '@/Types';

import { useEquipmentRowsStyles } from './EquipmentTable.styles';

export interface EquipmentAndServiceRowsProps {
  handleChange: (property: keyof EquipmentsAndServicesPartial, value: string, idx: number) => void;
  property: keyof EquipmentsAndServicesPartial;
  groupHeader?: string;
  disabled: boolean;
}

export const EquipmentAndServiceRows = (props: EquipmentAndServiceRowsProps) => {
  const { handleChange, property, groupHeader, disabled } = props;
  const { classes } = useEquipmentRowsStyles();
  const { values } = useFormikContext<EquipmentsAndServicesPartial>();
  const currentLanguage = useCurrentLanguage();

  const serviceValues: ServiceItem[] = values[property];

  return (
    <>
      {groupHeader && (
        <>
          <LineDivider verticalSpace={16} />
          <TableRow className={clsx(classes.tableRow, classes.tableRowGroup)}>
            <TableCell className={clsx(classes.tableCell, classes.tableCellHeader)} colSpan={4}>
              <Box id={property} className={classes.tableCellHeaderGroupContainer}>
                <SubHeadlineDisplay textContent={groupHeader} />
              </Box>
            </TableCell>
          </TableRow>
        </>
      )}
      {serviceValues.map((value, idx) => (
        <TableRow className={classes.tableRow} key={value.key}>
          <TableCell className={clsx(classes.tableCell, classes.tableCellHeader)}>
            <Box className={clsx(classes.tableCellContainer, classes.tableCellContainerFirst)}>
              <BodyTextSmall
                textContent={value.label[currentLanguage as 'de' | 'en']}
                fontWeight={500}
              />
            </Box>
          </TableCell>
          <TableCell className={clsx(classes.tableCell, classes.tableCellHeader)}>
            <Box className={clsx(classes.tableCellContainer, classes.borderRoundLeft)}>
              <Field
                name={value.key}
                component={FormikRadioGroup}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(property, e.target.value, idx)
                }>
                <FormControlLabel
                  checked={value.status === 'inclusive'}
                  value='inclusive'
                  control={<CustomizedRadio disableRipple color='primary' />}
                  label=''
                  disabled={disabled}
                />
              </Field>
            </Box>
          </TableCell>
          <TableCell className={clsx(classes.tableCell, classes.tableCellHeader)}>
            <Box
              className={clsx(
                {
                  [classes.borderRoundRight]: property === 'contractServices',
                },
                classes.tableCellContainer,
              )}>
              <Field
                name={value.key}
                component={FormikRadioGroup}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(property, e.target.value, idx)
                }>
                <FormControlLabel
                  checked={value.status === 'surcharge'}
                  value='surcharge'
                  control={<CustomizedRadio disableRipple color='primary' />}
                  label=''
                  disabled={disabled}
                />
              </Field>
            </Box>
          </TableCell>
          {property !== 'contractServices' && (
            <TableCell className={clsx(classes.tableCell, classes.tableCellHeader)}>
              <Box className={clsx(classes.tableCellContainer, classes.borderRoundRight)}>
                <Field
                  name={value.key}
                  component={FormikRadioGroup}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(property, e.target.value, idx)
                  }>
                  <FormControlLabel
                    checked={value.status === 'not_included'}
                    value='not_included'
                    control={<CustomizedRadio disableRipple color='primary' />}
                    label=''
                    disabled={disabled}
                  />
                </Field>
              </Box>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};
